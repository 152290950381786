@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Taviraj');

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  min-height: 62vh;
  position: relative;
  text-align: center;
  background-color: #87a597;
}

.logo img {
  max-width: 40vw;
  position: absolute;
  top: 20px;
  left: 20px;
}

.list {
  margin-bottom: 0;
}

.plustitle {
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "Taviraj", "NSimSun", "noto serif tc", serif !important;
  position: relative;
  top: -0.5em;
  z-index: 10;
  text-shadow: 0 1px 5px rgba(0, 0, 0, .2);

}

.count {
  color: #ffffff;
  font-size: 1.2rem;
  margin-top: .3em;
  position: relative;
  top: -0.5em;
  z-index: 10;
  text-shadow: 0 1px 5px rgba(0, 0, 0, .2);
}

[IonContent] {
  height: 100vh;
}

.loginform {
  width: 400px;
  margin: 0 auto !important;
  padding: 1em;
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
  top: -11em;
  z-index: 10;
}

ion-checkbox {
  --size: 22px;
}

.ptxt {
  font-size: 15px !important;
  color: #555 !important;
}
	
.ilogo {
      width: 300px;
    height: 150px;
    position: absolute;
    z-index: 10;
    top: 30px;
    left: 30px;
  background-image: url('/assets/icon/m_logo.png');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 50%;
}

.main_logo {
  width: 100%;
  height: 62vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

/* line 2306, ../sass/screen.scss */
.main_logo:before {
  content: "";
  display: block;
  width: 100vw;
  height: 90vh;
  position: absolute;
  top: 0;
  left: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00c9fe+0,208ffa+99 */
  background: #819D8F;
  /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, #8BBD99 0%, #819D8F 100%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, #8BBD99 0%, #819D8F 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, #8BBD99 0%, #819D8F 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8BBD99', endColorstr='#819D8F', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

/* line 2327, ../sass/screen.scss */
.main_logo:after {
  content: "";
  display: block;
  position: absolute;
  z-index: 6;
  width: 100%;
  height: 90vh;
}

/* line 2354, ../sass/screen.scss */
.bx1 {
  width: 12px;
  height: 12px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run1 21s linear infinite;
}

@keyframes run1 {
  0% {
    opacity: 0;
    transform: translate3d(392px, 181px, 0);
  }
  50% {
    opacity: -0.48814;
    transform: translate3d(-1 px392px, -5 px181px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(224px, 455px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx2 {
  width: 15px;
  height: 15px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run2 33s linear infinite;
}

@keyframes run2 {
  0% {
    opacity: 0;
    transform: translate3d(222px, 61px, 0);
  }
  50% {
    opacity: 0.06653;
    transform: translate3d(-23 px222px, 0 px61px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(273px, 293px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx3 {
  width: 21px;
  height: 21px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run3 20s linear infinite;
}

@keyframes run3 {
  0% {
    opacity: 0;
    transform: translate3d(225px, 89px, 0);
  }
  50% {
    opacity: 0.37096;
    transform: translate3d(-12 px225px, -26 px89px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(102px, 601px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx4 {
  width: 24px;
  height: 24px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run4 22s linear infinite;
}

@keyframes run4 {
  0% {
    opacity: 0;
    transform: translate3d(339px, 31px, 0);
  }
  50% {
    opacity: -0.42994;
    transform: translate3d(-29 px339px, -2 px31px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(421px, 62px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx5 {
  width: 15px;
  height: 15px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run5 20s linear infinite;
}

@keyframes run5 {
  0% {
    opacity: 0;
    transform: translate3d(436px, 170px, 0);
  }
  50% {
    opacity: -0.44999;
    transform: translate3d(-9 px436px, -2 px170px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(223px, -44px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx6 {
  width: 23px;
  height: 23px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run6 36s linear infinite;
}

@keyframes run6 {
  0% {
    opacity: 0;
    transform: translate3d(22px, 1px, 0);
  }
  50% {
    opacity: 0.26005;
    transform: translate3d(-25 px22px, -11 px1px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-77px, 678px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx7 {
  width: 9px;
  height: 9px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run7 24s linear infinite;
}

@keyframes run7 {
  0% {
    opacity: 0;
    transform: translate3d(214px, 325px, 0);
  }
  50% {
    opacity: 0.14745;
    transform: translate3d(-22 px214px, -7 px325px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(168px, -52px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx8 {
  width: 12px;
  height: 12px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run8 20s linear infinite;
}

@keyframes run8 {
  0% {
    opacity: 0;
    transform: translate3d(272px, -14px, 0);
  }
  50% {
    opacity: -0.31648;
    transform: translate3d(-15 px272px, -2 px-14px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(341px, 302px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx9 {
  width: 4px;
  height: 4px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run9 27s linear infinite;
}

@keyframes run9 {
  0% {
    opacity: 0;
    transform: translate3d(210px, 90px, 0);
  }
  50% {
    opacity: -0.15663;
    transform: translate3d(-7 px210px, -25 px90px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(214px, -41px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx10 {
  width: 15px;
  height: 15px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run10 28s linear infinite;
}

@keyframes run10 {
  0% {
    opacity: 0;
    transform: translate3d(272px, 141px, 0);
  }
  50% {
    opacity: 0.1879;
    transform: translate3d(-14 px272px, -2 px141px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(451px, 547px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx11 {
  width: 11px;
  height: 11px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run11 39s linear infinite;
}

@keyframes run11 {
  0% {
    opacity: 0;
    transform: translate3d(385px, 136px, 0);
  }
  50% {
    opacity: 0.32252;
    transform: translate3d(-18 px385px, -15 px136px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(502px, 273px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx12 {
  width: 23px;
  height: 23px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run12 18s linear infinite;
}

@keyframes run12 {
  0% {
    opacity: 0;
    transform: translate3d(61px, 153px, 0);
  }
  50% {
    opacity: 0.25778;
    transform: translate3d(-1 px61px, -10 px153px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(78px, 260px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx13 {
  width: 2px;
  height: 2px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run13 18s linear infinite;
}

@keyframes run13 {
  0% {
    opacity: 0;
    transform: translate3d(39px, 116px, 0);
  }
  50% {
    opacity: 0.27979;
    transform: translate3d(-4 px39px, -28 px116px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(211px, 537px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx14 {
  width: 8px;
  height: 8px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run14 40s linear infinite;
}

@keyframes run14 {
  0% {
    opacity: 0;
    transform: translate3d(50px, 19px, 0);
  }
  50% {
    opacity: -0.4742;
    transform: translate3d(-5 px50px, -15 px19px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(309px, 396px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx15 {
  width: 22px;
  height: 22px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run15 38s linear infinite;
}

@keyframes run15 {
  0% {
    opacity: 0;
    transform: translate3d(219px, 134px, 0);
  }
  50% {
    opacity: -0.37353;
    transform: translate3d(-27 px219px, -12 px134px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(434px, 165px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx16 {
  width: 3px;
  height: 3px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run16 20s linear infinite;
}

@keyframes run16 {
  0% {
    opacity: 0;
    transform: translate3d(281px, 321px, 0);
  }
  50% {
    opacity: 0.30621;
    transform: translate3d(-4 px281px, -23 px321px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(442px, 527px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx17 {
  width: 9px;
  height: 9px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run17 28s linear infinite;
}

@keyframes run17 {
  0% {
    opacity: 0;
    transform: translate3d(157px, 13px, 0);
  }
  50% {
    opacity: 0.47651;
    transform: translate3d(-29 px157px, -28 px13px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(47px, 13px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx18 {
  width: 18px;
  height: 18px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run18 30s linear infinite;
}

@keyframes run18 {
  0% {
    opacity: 0;
    transform: translate3d(277px, 341px, 0);
  }
  50% {
    opacity: -0.4166;
    transform: translate3d(-9 px277px, -12 px341px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-14px, -31px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx19 {
  width: 22px;
  height: 22px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run19 25s linear infinite;
}

@keyframes run19 {
  0% {
    opacity: 0;
    transform: translate3d(201px, 11px, 0);
  }
  50% {
    opacity: 0.09839;
    transform: translate3d(-9 px201px, -2 px11px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(370px, 346px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx20 {
  width: 18px;
  height: 18px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run20 27s linear infinite;
}

@keyframes run20 {
  0% {
    opacity: 0;
    transform: translate3d(418px, 66px, 0);
  }
  50% {
    opacity: 0.26352;
    transform: translate3d(-26 px418px, -13 px66px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-26px, 545px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx21 {
  width: 14px;
  height: 14px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run21 16s linear infinite;
}

@keyframes run21 {
  0% {
    opacity: 0;
    transform: translate3d(160px, 10px, 0);
  }
  50% {
    opacity: -0.44448;
    transform: translate3d(-11 px160px, -16 px10px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(25px, -9px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx22 {
  width: 20px;
  height: 20px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run22 23s linear infinite;
}

@keyframes run22 {
  0% {
    opacity: 0;
    transform: translate3d(-45px, 87px, 0);
  }
  50% {
    opacity: -0.06929;
    transform: translate3d(-5 px-45px, 0 px87px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(404px, -71px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx23 {
  width: 21px;
  height: 21px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run23 40s linear infinite;
}

@keyframes run23 {
  0% {
    opacity: 0;
    transform: translate3d(-17px, 249px, 0);
  }
  50% {
    opacity: 0.11083;
    transform: translate3d(-3 px-17px, -8 px249px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(327px, -65px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx24 {
  width: 8px;
  height: 8px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run24 31s linear infinite;
}

@keyframes run24 {
  0% {
    opacity: 0;
    transform: translate3d(150px, 94px, 0);
  }
  50% {
    opacity: -0.42237;
    transform: translate3d(-24 px150px, -6 px94px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(362px, 54px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx25 {
  width: 9px;
  height: 9px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run25 38s linear infinite;
}

@keyframes run25 {
  0% {
    opacity: 0;
    transform: translate3d(120px, 70px, 0);
  }
  50% {
    opacity: -0.46256;
    transform: translate3d(-21 px120px, -15 px70px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-2px, 47px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx26 {
  width: 11px;
  height: 11px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run26 30s linear infinite;
}

@keyframes run26 {
  0% {
    opacity: 0;
    transform: translate3d(431px, 38px, 0);
  }
  50% {
    opacity: 0.37216;
    transform: translate3d(0 px431px, -10 px38px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(324px, 272px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx27 {
  width: 20px;
  height: 20px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run27 20s linear infinite;
}

@keyframes run27 {
  0% {
    opacity: 0;
    transform: translate3d(60px, 84px, 0);
  }
  50% {
    opacity: -0.4111;
    transform: translate3d(-25 px60px, -15 px84px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(435px, 543px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx28 {
  width: 14px;
  height: 14px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run28 37s linear infinite;
}

@keyframes run28 {
  0% {
    opacity: 0;
    transform: translate3d(251px, -27px, 0);
  }
  50% {
    opacity: 0.10353;
    transform: translate3d(-21 px251px, -13 px-27px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-77px, 208px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx29 {
  width: 1px;
  height: 1px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run29 38s linear infinite;
}

@keyframes run29 {
  0% {
    opacity: 0;
    transform: translate3d(353px, 262px, 0);
  }
  50% {
    opacity: 0.1375;
    transform: translate3d(-3 px353px, -19 px262px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(273px, -18px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx30 {
  width: 14px;
  height: 14px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run30 36s linear infinite;
}

@keyframes run30 {
  0% {
    opacity: 0;
    transform: translate3d(247px, 198px, 0);
  }
  50% {
    opacity: -0.38851;
    transform: translate3d(-6 px247px, -20 px198px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(12px, 284px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx31 {
  width: 22px;
  height: 22px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run31 24s linear infinite;
}

@keyframes run31 {
  0% {
    opacity: 0;
    transform: translate3d(222px, 86px, 0);
  }
  50% {
    opacity: -0.01222;
    transform: translate3d(-26 px222px, -27 px86px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(51px, 204px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx32 {
  width: 12px;
  height: 12px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run32 39s linear infinite;
}

@keyframes run32 {
  0% {
    opacity: 0;
    transform: translate3d(-26px, 270px, 0);
  }
  50% {
    opacity: -0.29778;
    transform: translate3d(-29 px-26px, -17 px270px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(423px, 412px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx33 {
  width: 20px;
  height: 20px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run33 39s linear infinite;
}

@keyframes run33 {
  0% {
    opacity: 0;
    transform: translate3d(263px, 119px, 0);
  }
  50% {
    opacity: -0.31704;
    transform: translate3d(-13 px263px, -18 px119px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(80px, 224px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx34 {
  width: 10px;
  height: 10px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run34 37s linear infinite;
}

@keyframes run34 {
  0% {
    opacity: 0;
    transform: translate3d(210px, 122px, 0);
  }
  50% {
    opacity: -0.06628;
    transform: translate3d(-8 px210px, -7 px122px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(250px, 276px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx35 {
  width: 20px;
  height: 20px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run35 19s linear infinite;
}

@keyframes run35 {
  0% {
    opacity: 0;
    transform: translate3d(30px, 197px, 0);
  }
  50% {
    opacity: -0.0089;
    transform: translate3d(-4 px30px, -26 px197px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-85px, 603px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx36 {
  width: 2px;
  height: 2px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run36 24s linear infinite;
}

@keyframes run36 {
  0% {
    opacity: 0;
    transform: translate3d(277px, -7px, 0);
  }
  50% {
    opacity: -0.27361;
    transform: translate3d(-8 px277px, -1 px-7px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(227px, 144px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx37 {
  width: 5px;
  height: 5px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run37 27s linear infinite;
}

@keyframes run37 {
  0% {
    opacity: 0;
    transform: translate3d(-38px, 226px, 0);
  }
  50% {
    opacity: 0.41348;
    transform: translate3d(-1 px-38px, -13 px226px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(402px, 512px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx38 {
  width: 4px;
  height: 4px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run38 28s linear infinite;
}

@keyframes run38 {
  0% {
    opacity: 0;
    transform: translate3d(166px, 302px, 0);
  }
  50% {
    opacity: -0.22564;
    transform: translate3d(-10 px166px, -22 px302px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(329px, 34px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx39 {
  width: 10px;
  height: 10px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run39 20s linear infinite;
}

@keyframes run39 {
  0% {
    opacity: 0;
    transform: translate3d(432px, 219px, 0);
  }
  50% {
    opacity: 0.46324;
    transform: translate3d(-18 px432px, -7 px219px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(149px, 554px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx40 {
  width: 2px;
  height: 2px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run40 19s linear infinite;
}

@keyframes run40 {
  0% {
    opacity: 0;
    transform: translate3d(381px, -30px, 0);
  }
  50% {
    opacity: 0.31324;
    transform: translate3d(-4 px381px, -18 px-30px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-62px, 40px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx41 {
  width: 9px;
  height: 9px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run41 17s linear infinite;
}

@keyframes run41 {
  0% {
    opacity: 0;
    transform: translate3d(324px, 151px, 0);
  }
  50% {
    opacity: 0.17675;
    transform: translate3d(-3 px324px, -17 px151px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(546px, 668px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx42 {
  width: 3px;
  height: 3px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run42 35s linear infinite;
}

@keyframes run42 {
  0% {
    opacity: 0;
    transform: translate3d(383px, 236px, 0);
  }
  50% {
    opacity: -0.33542;
    transform: translate3d(-11 px383px, -29 px236px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(540px, 280px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx43 {
  width: 9px;
  height: 9px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run43 34s linear infinite;
}

@keyframes run43 {
  0% {
    opacity: 0;
    transform: translate3d(187px, 100px, 0);
  }
  50% {
    opacity: 0.20576;
    transform: translate3d(-3 px187px, -8 px100px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-80px, 660px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx44 {
  width: 7px;
  height: 7px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run44 16s linear infinite;
}

@keyframes run44 {
  0% {
    opacity: 0;
    transform: translate3d(8px, 98px, 0);
  }
  50% {
    opacity: 0.19125;
    transform: translate3d(-9 px8px, -14 px98px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(37px, 431px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx45 {
  width: 17px;
  height: 17px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run45 38s linear infinite;
}

@keyframes run45 {
  0% {
    opacity: 0;
    transform: translate3d(190px, 337px, 0);
  }
  50% {
    opacity: 0.17822;
    transform: translate3d(-19 px190px, -27 px337px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(225px, 6px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx46 {
  width: 8px;
  height: 8px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run46 17s linear infinite;
}

@keyframes run46 {
  0% {
    opacity: 0;
    transform: translate3d(414px, -38px, 0);
  }
  50% {
    opacity: 0.38822;
    transform: translate3d(-15 px414px, -24 px-38px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(178px, 191px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx47 {
  width: 15px;
  height: 15px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run47 38s linear infinite;
}

@keyframes run47 {
  0% {
    opacity: 0;
    transform: translate3d(99px, -34px, 0);
  }
  50% {
    opacity: -0.08102;
    transform: translate3d(-27 px99px, -22 px-34px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(68px, 443px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx48 {
  width: 21px;
  height: 21px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run48 20s linear infinite;
}

@keyframes run48 {
  0% {
    opacity: 0;
    transform: translate3d(80px, 104px, 0);
  }
  50% {
    opacity: 0.42564;
    transform: translate3d(-15 px80px, -28 px104px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(550px, 490px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx49 {
  width: 18px;
  height: 18px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run49 37s linear infinite;
}

@keyframes run49 {
  0% {
    opacity: 0;
    transform: translate3d(73px, 171px, 0);
  }
  50% {
    opacity: 0.44675;
    transform: translate3d(-23 px73px, -8 px171px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(554px, 642px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx50 {
  width: 3px;
  height: 3px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run50 18s linear infinite;
}

@keyframes run50 {
  0% {
    opacity: 0;
    transform: translate3d(381px, 317px, 0);
  }
  50% {
    opacity: 0.04544;
    transform: translate3d(-6 px381px, -4 px317px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(106px, 625px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx51 {
  width: 13px;
  height: 13px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run51 20s linear infinite;
}

@keyframes run51 {
  0% {
    opacity: 0;
    transform: translate3d(420px, 292px, 0);
  }
  50% {
    opacity: -0.33006;
    transform: translate3d(-15 px420px, -9 px292px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(256px, 504px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx52 {
  width: 21px;
  height: 21px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run52 18s linear infinite;
}

@keyframes run52 {
  0% {
    opacity: 0;
    transform: translate3d(235px, 107px, 0);
  }
  50% {
    opacity: -0.45314;
    transform: translate3d(-21 px235px, -9 px107px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(304px, 0px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx53 {
  width: 23px;
  height: 23px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run53 20s linear infinite;
}

@keyframes run53 {
  0% {
    opacity: 0;
    transform: translate3d(130px, 189px, 0);
  }
  50% {
    opacity: 0.3356;
    transform: translate3d(-24 px130px, -1 px189px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-62px, 299px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx54 {
  width: 3px;
  height: 3px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run54 31s linear infinite;
}

@keyframes run54 {
  0% {
    opacity: 0;
    transform: translate3d(91px, 123px, 0);
  }
  50% {
    opacity: -0.11544;
    transform: translate3d(0 px91px, -15 px123px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-25px, 481px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx55 {
  width: 23px;
  height: 23px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run55 16s linear infinite;
}

@keyframes run55 {
  0% {
    opacity: 0;
    transform: translate3d(417px, 41px, 0);
  }
  50% {
    opacity: 0.31668;
    transform: translate3d(-21 px417px, -17 px41px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(586px, 171px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx56 {
  width: 7px;
  height: 7px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run56 31s linear infinite;
}

@keyframes run56 {
  0% {
    opacity: 0;
    transform: translate3d(246px, 309px, 0);
  }
  50% {
    opacity: -0.2606;
    transform: translate3d(-2 px246px, -12 px309px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-35px, 243px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx57 {
  width: 11px;
  height: 11px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run57 16s linear infinite;
}

@keyframes run57 {
  0% {
    opacity: 0;
    transform: translate3d(304px, 139px, 0);
  }
  50% {
    opacity: -0.37327;
    transform: translate3d(-13 px304px, -28 px139px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(12px, 208px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx58 {
  width: 15px;
  height: 15px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run58 33s linear infinite;
}

@keyframes run58 {
  0% {
    opacity: 0;
    transform: translate3d(89px, 328px, 0);
  }
  50% {
    opacity: -0.39174;
    transform: translate3d(-27 px89px, -7 px328px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(311px, 250px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx59 {
  width: 7px;
  height: 7px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run59 32s linear infinite;
}

@keyframes run59 {
  0% {
    opacity: 0;
    transform: translate3d(359px, 46px, 0);
  }
  50% {
    opacity: -0.3177;
    transform: translate3d(-22 px359px, -21 px46px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(91px, 210px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx60 {
  width: 17px;
  height: 17px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run60 35s linear infinite;
}

@keyframes run60 {
  0% {
    opacity: 0;
    transform: translate3d(153px, 176px, 0);
  }
  50% {
    opacity: -0.1447;
    transform: translate3d(-14 px153px, -16 px176px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(213px, 335px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx61 {
  width: 12px;
  height: 12px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run61 24s linear infinite;
}

@keyframes run61 {
  0% {
    opacity: 0;
    transform: translate3d(282px, 279px, 0);
  }
  50% {
    opacity: 0.04146;
    transform: translate3d(-10 px282px, -14 px279px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(128px, 611px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx62 {
  width: 16px;
  height: 16px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run62 39s linear infinite;
}

@keyframes run62 {
  0% {
    opacity: 0;
    transform: translate3d(342px, -3px, 0);
  }
  50% {
    opacity: -0.43513;
    transform: translate3d(-11 px342px, -12 px-3px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(409px, 481px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx63 {
  width: 7px;
  height: 7px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run63 30s linear infinite;
}

@keyframes run63 {
  0% {
    opacity: 0;
    transform: translate3d(272px, 331px, 0);
  }
  50% {
    opacity: -0.21568;
    transform: translate3d(-18 px272px, -21 px331px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(113px, 329px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx64 {
  width: 16px;
  height: 16px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run64 34s linear infinite;
}

@keyframes run64 {
  0% {
    opacity: 0;
    transform: translate3d(371px, 148px, 0);
  }
  50% {
    opacity: 0.27213;
    transform: translate3d(-8 px371px, -28 px148px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-3px, 399px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx65 {
  width: 1px;
  height: 1px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run65 34s linear infinite;
}

@keyframes run65 {
  0% {
    opacity: 0;
    transform: translate3d(336px, 250px, 0);
  }
  50% {
    opacity: -0.25104;
    transform: translate3d(-9 px336px, -14 px250px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(173px, 248px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx66 {
  width: 8px;
  height: 8px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run66 38s linear infinite;
}

@keyframes run66 {
  0% {
    opacity: 0;
    transform: translate3d(101px, 66px, 0);
  }
  50% {
    opacity: -0.2497;
    transform: translate3d(-12 px101px, -12 px66px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(24px, 431px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx67 {
  width: 11px;
  height: 11px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run67 35s linear infinite;
}

@keyframes run67 {
  0% {
    opacity: 0;
    transform: translate3d(327px, 312px, 0);
  }
  50% {
    opacity: -0.42382;
    transform: translate3d(-10 px327px, -25 px312px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(170px, 391px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx68 {
  width: 12px;
  height: 12px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run68 23s linear infinite;
}

@keyframes run68 {
  0% {
    opacity: 0;
    transform: translate3d(401px, 260px, 0);
  }
  50% {
    opacity: -0.01528;
    transform: translate3d(-4 px401px, -28 px260px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(564px, 460px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx69 {
  width: 4px;
  height: 4px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run69 25s linear infinite;
}

@keyframes run69 {
  0% {
    opacity: 0;
    transform: translate3d(-22px, 332px, 0);
  }
  50% {
    opacity: -0.30532;
    transform: translate3d(-1 px-22px, -13 px332px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-36px, 288px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx70 {
  width: 24px;
  height: 24px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run70 30s linear infinite;
}

@keyframes run70 {
  0% {
    opacity: 0;
    transform: translate3d(150px, 170px, 0);
  }
  50% {
    opacity: -0.08162;
    transform: translate3d(-16 px150px, -11 px170px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-60px, 240px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx71 {
  width: 2px;
  height: 2px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run71 31s linear infinite;
}

@keyframes run71 {
  0% {
    opacity: 0;
    transform: translate3d(358px, 87px, 0);
  }
  50% {
    opacity: 0.37856;
    transform: translate3d(-29 px358px, -2 px87px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-23px, 115px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx72 {
  width: 9px;
  height: 9px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run72 23s linear infinite;
}

@keyframes run72 {
  0% {
    opacity: 0;
    transform: translate3d(-36px, 304px, 0);
  }
  50% {
    opacity: 0.46763;
    transform: translate3d(-24 px-36px, -14 px304px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-54px, 595px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx73 {
  width: 11px;
  height: 11px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run73 37s linear infinite;
}

@keyframes run73 {
  0% {
    opacity: 0;
    transform: translate3d(95px, 7px, 0);
  }
  50% {
    opacity: 0.4015;
    transform: translate3d(-11 px95px, -21 px7px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(541px, 314px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx74 {
  width: 16px;
  height: 16px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run74 31s linear infinite;
}

@keyframes run74 {
  0% {
    opacity: 0;
    transform: translate3d(130px, 259px, 0);
  }
  50% {
    opacity: -0.30326;
    transform: translate3d(-23 px130px, -25 px259px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(598px, 338px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx75 {
  width: 11px;
  height: 11px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run75 17s linear infinite;
}

@keyframes run75 {
  0% {
    opacity: 0;
    transform: translate3d(351px, 100px, 0);
  }
  50% {
    opacity: -0.44817;
    transform: translate3d(-13 px351px, -16 px100px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(339px, 561px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx76 {
  width: 12px;
  height: 12px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run76 38s linear infinite;
}

@keyframes run76 {
  0% {
    opacity: 0;
    transform: translate3d(181px, 270px, 0);
  }
  50% {
    opacity: -0.4777;
    transform: translate3d(-2 px181px, -5 px270px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-53px, 103px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx77 {
  width: 8px;
  height: 8px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run77 18s linear infinite;
}

@keyframes run77 {
  0% {
    opacity: 0;
    transform: translate3d(345px, 42px, 0);
  }
  50% {
    opacity: 0.30379;
    transform: translate3d(-2 px345px, -19 px42px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-33px, 514px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx78 {
  width: 2px;
  height: 2px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run78 27s linear infinite;
}

@keyframes run78 {
  0% {
    opacity: 0;
    transform: translate3d(124px, 169px, 0);
  }
  50% {
    opacity: -0.23693;
    transform: translate3d(-16 px124px, -2 px169px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(465px, 684px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx79 {
  width: 3px;
  height: 3px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run79 23s linear infinite;
}

@keyframes run79 {
  0% {
    opacity: 0;
    transform: translate3d(104px, 264px, 0);
  }
  50% {
    opacity: 0.05146;
    transform: translate3d(-8 px104px, -21 px264px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(102px, 180px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx80 {
  width: 12px;
  height: 12px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run80 34s linear infinite;
}

@keyframes run80 {
  0% {
    opacity: 0;
    transform: translate3d(255px, 207px, 0);
  }
  50% {
    opacity: -0.43136;
    transform: translate3d(-22 px255px, -29 px207px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(306px, 12px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx81 {
  width: 13px;
  height: 13px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run81 18s linear infinite;
}

@keyframes run81 {
  0% {
    opacity: 0;
    transform: translate3d(157px, -42px, 0);
  }
  50% {
    opacity: 0.39321;
    transform: translate3d(-25 px157px, -5 px-42px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(132px, 139px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx82 {
  width: 5px;
  height: 5px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run82 19s linear infinite;
}

@keyframes run82 {
  0% {
    opacity: 0;
    transform: translate3d(324px, 233px, 0);
  }
  50% {
    opacity: 0.07186;
    transform: translate3d(-2 px324px, -28 px233px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(472px, 28px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx83 {
  width: 1px;
  height: 1px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run83 30s linear infinite;
}

@keyframes run83 {
  0% {
    opacity: 0;
    transform: translate3d(360px, 251px, 0);
  }
  50% {
    opacity: 0.40341;
    transform: translate3d(-8 px360px, -4 px251px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(476px, -59px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx84 {
  width: 2px;
  height: 2px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run84 36s linear infinite;
}

@keyframes run84 {
  0% {
    opacity: 0;
    transform: translate3d(430px, -13px, 0);
  }
  50% {
    opacity: -0.09388;
    transform: translate3d(-18 px430px, 0 px-13px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(175px, 232px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx85 {
  width: 23px;
  height: 23px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run85 35s linear infinite;
}

@keyframes run85 {
  0% {
    opacity: 0;
    transform: translate3d(-12px, 298px, 0);
  }
  50% {
    opacity: 0.06078;
    transform: translate3d(-25 px-12px, -4 px298px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(319px, 624px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx86 {
  width: 5px;
  height: 5px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run86 39s linear infinite;
}

@keyframes run86 {
  0% {
    opacity: 0;
    transform: translate3d(27px, 277px, 0);
  }
  50% {
    opacity: 0.05899;
    transform: translate3d(-20 px27px, -28 px277px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(294px, 231px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx87 {
  width: 18px;
  height: 18px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run87 18s linear infinite;
}

@keyframes run87 {
  0% {
    opacity: 0;
    transform: translate3d(92px, 80px, 0);
  }
  50% {
    opacity: 0.07011;
    transform: translate3d(-25 px92px, -11 px80px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(265px, 485px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx88 {
  width: 6px;
  height: 6px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run88 24s linear infinite;
}

@keyframes run88 {
  0% {
    opacity: 0;
    transform: translate3d(212px, 7px, 0);
  }
  50% {
    opacity: 0.19231;
    transform: translate3d(-9 px212px, -20 px7px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(483px, 676px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx89 {
  width: 22px;
  height: 22px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run89 16s linear infinite;
}

@keyframes run89 {
  0% {
    opacity: 0;
    transform: translate3d(172px, 287px, 0);
  }
  50% {
    opacity: 0.28739;
    transform: translate3d(-20 px172px, -20 px287px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(50px, 99px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx90 {
  width: 20px;
  height: 20px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run90 20s linear infinite;
}

@keyframes run90 {
  0% {
    opacity: 0;
    transform: translate3d(65px, -12px, 0);
  }
  50% {
    opacity: -0.22107;
    transform: translate3d(-18 px65px, -28 px-12px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-24px, 472px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx91 {
  width: 16px;
  height: 16px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run91 17s linear infinite;
}

@keyframes run91 {
  0% {
    opacity: 0;
    transform: translate3d(23px, 149px, 0);
  }
  50% {
    opacity: 0.45386;
    transform: translate3d(-13 px23px, -12 px149px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(458px, 418px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx92 {
  width: 23px;
  height: 23px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run92 38s linear infinite;
}

@keyframes run92 {
  0% {
    opacity: 0;
    transform: translate3d(-37px, 1px, 0);
  }
  50% {
    opacity: 0.13398;
    transform: translate3d(-18 px-37px, -5 px1px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(206px, -12px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx93 {
  width: 23px;
  height: 23px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run93 24s linear infinite;
}

@keyframes run93 {
  0% {
    opacity: 0;
    transform: translate3d(179px, 100px, 0);
  }
  50% {
    opacity: 0.45527;
    transform: translate3d(-2 px179px, -21 px100px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(300px, 609px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx94 {
  width: 24px;
  height: 24px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run94 18s linear infinite;
}

@keyframes run94 {
  0% {
    opacity: 0;
    transform: translate3d(175px, 174px, 0);
  }
  50% {
    opacity: 0.45401;
    transform: translate3d(-29 px175px, -4 px174px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(163px, -7px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx95 {
  width: 18px;
  height: 18px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run95 31s linear infinite;
}

@keyframes run95 {
  0% {
    opacity: 0;
    transform: translate3d(203px, 175px, 0);
  }
  50% {
    opacity: 0.09118;
    transform: translate3d(-18 px203px, -27 px175px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(2px, 609px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx96 {
  width: 7px;
  height: 7px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run96 35s linear infinite;
}

@keyframes run96 {
  0% {
    opacity: 0;
    transform: translate3d(352px, 19px, 0);
  }
  50% {
    opacity: -0.31623;
    transform: translate3d(-28 px352px, -12 px19px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(291px, 304px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx97 {
  width: 1px;
  height: 1px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run97 28s linear infinite;
}

@keyframes run97 {
  0% {
    opacity: 0;
    transform: translate3d(49px, 41px, 0);
  }
  50% {
    opacity: 0.42968;
    transform: translate3d(-13 px49px, 0 px41px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-6px, 133px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx98 {
  width: 13px;
  height: 13px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run98 20s linear infinite;
}

@keyframes run98 {
  0% {
    opacity: 0;
    transform: translate3d(121px, 104px, 0);
  }
  50% {
    opacity: -0.39863;
    transform: translate3d(-23 px121px, -6 px104px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(399px, 406px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx99 {
  width: 5px;
  height: 5px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run99 32s linear infinite;
}

@keyframes run99 {
  0% {
    opacity: 0;
    transform: translate3d(37px, 132px, 0);
  }
  50% {
    opacity: -0.00664;
    transform: translate3d(-19 px37px, -1 px132px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-3px, 244px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx100 {
  width: 22px;
  height: 22px;
  transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  animation: run100 33s linear infinite;
}

@keyframes run100 {
  0% {
    opacity: 0;
    transform: translate3d(254px, 239px, 0);
  }
  50% {
    opacity: 0.30361;
    transform: translate3d(-16 px254px, -16 px239px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(82px, -31px, 0);
  }
}
@media screen and (max-width: 420px) {
	.ilogo {
  width: 50vw;
  height: 25vw;
  position: absolute;
  z-index: 10;
  top: 5vw;
  left: 5vw;
  background-image: url('/assets/icon/m_logo.png');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 80%;
}
.loginform {
  width: 90vw;
  margin: 0 auto !important;
  padding: 1em;
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
  top: -9em;
  z-index: 10;
}
}