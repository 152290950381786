.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

ion-item {
  margin-bottom: .5em;
}

.titlebar {
  display: flex;
  align-items: center;
  padding: 1em;
  margin-top: 1em;
}

.titlebar h3 {
  /*font-size:.9em;*/
  font-size: 21px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
  color: #345447;
}

.titlebar .note {
  display: flex;
  color: #777;
  font-size: 12px;
  margin-left: 1em;
}

.titlebar .note span, .inputform ion-label span {
  color: #D0021B;
}
