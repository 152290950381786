.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.atlist-page .ctpbox {
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.atlist-page ion-card-title {
  font-size: 1.4rem;
  color: #424242;
  line-height: 1.2em;
}

.atlist-page ion-card-subtitle span:nth-child(2):before {
  content: "・";
}

.stulist{
	display:flex;
	flex-wrap:wrap;
}
.stulist ion-card{
	width:30%;
}
@media screen and (max-width: 430px) {
	.stulist{
	display:block;
}
.stulist ion-card{
	width:auto;
}
}
