.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.nocontent .noteintro {
  width: 100%;
  padding: 1em 0.5em !important;
  border-bottom:1px solid #ccc;
}
.label-stacked.sc-ion-label-ios-h{
  font-size:17px;
}

.native-textarea.sc-ion-textarea-ios{
  font-size:15px;
}

.required{
  color: #D0021B;
}
