.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.stmgr .stinfobar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  padding: 2px 0;
}

.stmgr .stinfobar .txt {
  font-size: 14px;
  margin: 0 5px;
  color: #666;
}

.stmgr ion-label .info {
  font-size: 14px;
  margin-top: 3px;
  color: #999;
}

.stmgr ion-label h3 {
  font-size: 1.1em;
  font-weight: 500;
  color: #424242;
}

.cubeline {
  width: 100%;
  height: 2px;
}


.hightlighttitle{
  color:#ea7d1c;
}
.hightlighttitle2{
  color:#3278E4;
}

.cb_red {
  background-color: #ea7d1c;
}
.cb_blue {
  background-color: #3278E4;
}

.cb_green{
  background-color: #86aa93;
}

.item_info span:empty{
  display: none;
}
.item_info span:after{
  content:" / ";
  font-weight: 300;
  padding:0 .5em;
}

.item_info span:last-child:after{
  content:""
}


