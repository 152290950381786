.about-header {
    background-color: #434954;
    padding: 16px;
    width: 100%;
    min-height: 150px;
    text-align: center;
}

.about-header img {
    max-width: 200px;
    min-height: 115px;
    margin-left: -15px;
    padding: 25px 0 20px 0;
}

.about-info p {
    color: #697072;
    text-align: left;
}

.about-info ion-icon {
    width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
    margin-right: 0;
}

.ios .about-info {
    text-align: center;
}

.ios .about-info ion-icon {
    width: auto;
    margin-right: 10px;
}

.arx {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.arx .topcontent {
    width: 45%;
    padding: 2em 1em 1em;

}

.arx .topbox {
    position: sticky;
    top: 2em;
}

.arx .atcontent {
    width: 55%
}

.atcontent-page .photobox {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
}

.atcontent-page .photobox img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.atcontent-page .topic {
    padding: .5em 1em;
}

.atcontent-page .topic h3 {
    font-size: 1.6rem;
    line-height: 1.4em;
    color: #333;
}

.atcontent-page .topic span {
    color: #999;
}

.atcontent-page .topic span:before {
    content: "・";
}

.atcontent-page .topic span:nth-child(2):before {
    content: "";
}

.atcontent-page .atcontent {
    padding: 0 1em 1em;
}

.atcontent-page .atcontent p {
    color: #424242;
    font-size: 1.4rem;
    line-height: 1.6em;
    text-align: justify;
    margin-bottom: 1.8em;
}

.video {
    position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        overflow: hidden;
        height: 0;
    }

    .video iframe,
    .video object,
    .video embed {

        position: absolute;

        top: 0;
        left: 0;

        width: 100%;

        height: 100%;
    }

@media screen and (max-width: 430px) {

    .arx {
        display: block;
        position: relative;
    }

    .arx .topcontent {
        width: 100%;
        padding: 0;

    }

    .arx .topbox {
        position: inherit;
        top: 0;
    }

    .arx .atcontent {
        width: 100%;

    }

    .video {
    position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        overflow: hidden;
        height: 0;
    }

    .video iframe,
    .video object,
    .video embed {

        position: absolute;

        top: 0;
        left: 0;

        width: 100%;

        height: 100%;
    }

    .atcontent-page .photobox {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: calc(100% * 0.75);
    }
}