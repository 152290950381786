ion-item-sliding.track-ionic ion-label {
  border-left: 2px solid var(--ion-color-primary);
  padding-left: 10px;
}

ion-item-sliding.track-angular ion-label {
  border-left: 2px solid var(--ion-color-angular);
  padding-left: 10px;
}

ion-item-sliding.track-communication ion-label {
  border-left: 2px solid var(--ion-color-communication);
  padding-left: 10px;
}

ion-item-sliding.track-tooling ion-label {
  border-left: 2px solid var(--ion-color-tooling);
  padding-left: 10px;
}

ion-item-sliding.track-services ion-label {
  border-left: 2px solid var(--ion-color-services);
  padding-left: 10px;
}

ion-item-sliding.track-design ion-label {
  border-left: 2px solid var(--ion-color-design);
  padding-left: 10px;
}

ion-item-sliding.track-workshop ion-label {
  border-left: 2px solid var(--ion-color-workshop);
  padding-left: 10px;
}

ion-item-sliding.track-food ion-label {
  border-left: 2px solid var(--ion-color-food);
  padding-left: 10px;
}

ion-item-sliding.track-documentation ion-label {
  border-left: 2px solid var(--ion-color-documentation);
  padding-left: 10px;
}

ion-item-sliding.track-navigation ion-label {
  border-left: 2px solid var(--ion-color-navigation);
  padding-left: 10px;
}

.setting-page {
}

.setting-page .serviceinfo {
  margin-top: 5vh;
  padding: 1em;
}

.setting-page .serviceinfo h3 {
  font-size: 22px;
  font-weight: 400;
  color: #345447;
  margin-bottom: 1em;
}

.setting-page .serviceinfo ul {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #424242;
  line-height: 1.4;
}

.setting-page .serviceinfo li {
  margin-bottom: 5px;
}

.setting-page .serviceinfo .cy {
  margin-top: 10px;
  font-size: 13px;
  color: #999;
}

@media screen and (max-width: 360px) {
  .setting-page .serviceinfo {
    margin-top: 2vh;
  }

  .setting-page .serviceinfo h3 {
    font-size: 19px;
  }

  .setting-page .serviceinfo li {
    font-size: 14px;
  }

  .setting-page .serviceinfo .cy {
    margin-top: 5px;
  }

}

@media screen and (max-width: 320px) {
  .setting-page .serviceinfo {
    margin-top: 0;
    padding: 0 1em;
  }

  .setting-page .serviceinfo h3 {
    font-size: 17px;
  }

  .setting-page .serviceinfo li {
    font-size: 13px;
  }

  .setting-page .serviceinfo .cy {
    margin-top: 0;
  }

}
