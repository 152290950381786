ion-item-sliding.track-ionic ion-label {
  border-left: 2px solid var(--ion-color-primary);
  padding-left: 10px;
}

ion-item-sliding.track-angular ion-label {
  border-left: 2px solid var(--ion-color-angular);
  padding-left: 10px;
}

ion-item-sliding.track-communication ion-label {
  border-left: 2px solid var(--ion-color-communication);
  padding-left: 10px;
}

ion-item-sliding.track-tooling ion-label {
  border-left: 2px solid var(--ion-color-tooling);
  padding-left: 10px;
}

ion-item-sliding.track-services ion-label {
  border-left: 2px solid var(--ion-color-services);
  padding-left: 10px;
}

ion-item-sliding.track-design ion-label {
  border-left: 2px solid var(--ion-color-design);
  padding-left: 10px;
}

ion-item-sliding.track-workshop ion-label {
  border-left: 2px solid var(--ion-color-workshop);
  padding-left: 10px;
}

ion-item-sliding.track-food ion-label {
  border-left: 2px solid var(--ion-color-food);
  padding-left: 10px;
}

ion-item-sliding.track-documentation ion-label {
  border-left: 2px solid var(--ion-color-documentation);
  padding-left: 10px;
}

ion-item-sliding.track-navigation ion-label {
  border-left: 2px solid var(--ion-color-navigation);
  padding-left: 10px;
}
.termsinfo{
  padding:15px;
}

.termsinfo h2{
  font-size:1.5em;
}
.termsinfo h3{
  margin-top: 2em;
  font-size:1.1em;
}
.termsinfo p,.termsinfo li{
  margin-bottom: 1.5em;
}
.termsinfo ol > li > ol{
  margin-top:.5em;
}
.termsinfo ol > li > ol >li{
  list-style-type: upper-alpha; /*大寫英文字母*/
  margin-bottom: 1.1em;
}
