.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.ios ion-segment-button {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #eee;
  border-radius: 0;
  padding: .5em 0;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 1px;
}

.ios .segment-button-checked {
  border-bottom: 1px solid #87A597;
}
.slideroption ion-item{
	margin-bottom:0 !important;
}

.title{
  font-size:1.4rem;
  font-weight: bold;
  margin-bottom: .25em;
  color:#345447;
}

.ptxt .started:before{
  content:"、";
}
.ptxt .started:first-child:before{
  content:"";
}

.instructions{
  color:#757575;
  padding-right:.5em;
  font-size:.95em;
}

.instructions ul{
  list-style: square;
}

.instructions ul li{
  padding:.3em 0;
}

.txtcontent{
  width:100%;
}

.modal_page{
  width:90%;
  height:100vh;
  margin:0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal_page .choice_item{
  width:85%;
  margin:0 auto 2em;
}

.item_th{
  color:#555;
  font-size:.9em;
}

.item_th .bold{
  font-weight: bold;
}


.modal_page .choice_item.xs{
  width:50%;
  margin:0 auto 2em;
}
.narrow{
  line-height: 1em;
  margin:0;
  position: relative;
  top:-2px;
}

ion-toast{
  font-size:1.1em;
}

.toast-wrapper{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height:100vh;
  padding: 0 2em;
  z-index:5;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.toast-wrapper .toast-box{
  width:95%;
  background-color: rgba(0,0,0,.85);
  line-height: 1.2;
  padding:.7em 1.2em;
  border-radius: 10px;
  position: relative;
  display: none;
}

.toast-wrapper .toast-box .toast-msg{
  color:#fff;
  font-size:1.1em;
  text-align: center;
}

.toast-wrapper.active .toast-box{
  display: block;
  animation-name:toasteffects;
  animation-timing-function:ease-in-out;
  animation-duration:5s;
  animation-iteration-count: 1;
  opacity: 0;
}

@keyframes toasteffects{
  0%{
      top:20px;
      opacity: 0;
    }
    10%{
        top:0;
        opacity: 1;
    }

    90%{
      top:0;
      opacity: 1;
  }

  100%{
    top:20px;
    opacity: 0;
}
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 1s ease-in-out;
}