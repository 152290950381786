.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}
.quandan-page{
  background: #eee;
}

.quandan-page h3{
  padding:0 15px ;
  font-weight: 300;
  font-size:1.5em;
}

.quandan-page ion-label {
  padding: 0.5em 0;
}
.quandan-page ion-list{
  background: transparent;
}
.quandan-page .qusty {
  display: flex;
  align-items: flex-start;
  font-size: 1.1rem;
  color: #D2691E;
}

.quandan-page .qusty .qtxt {
  font-size: 1.4rem;
  font-weight: 300;
  color: #D2691E;
  margin-right: 0.3em;
  position: relative;
  top:-5px;
}

.quandan-page .ans {
  margin-top: 1.4em;
  padding:30px 20px 25px 20px;
  margin-right: 0.5em;
  color: #424242;
  border:1px dotted #87a597;
  border-radius: 18px;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  /*#3880ff;*/
}
.quandan-page .ans:before {
    content:"";
    display: block;
    width:36px;
    height:36px;
    background-image: url('/assets/icon/ans.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  position: absolute;
  z-index:3;
  top:-18px;
  left:5px;
  }
.quandan-page .ans .th{
  display: flex;
  font-size: 1.1rem;
  line-height: 1.2em;
  letter-spacing: 0.02em;
  color:#657C74;
  font-weight: 500;
  position: absolute;
  padding: 5px .5em;
  margin-right:1em;
  top:-15px;
  left:33px;
  background: #fff;
  border-radius: 10px;
}
.quandan-page .ans ol{
  margin-left:22px;
  padding-left:0;
}
.quandan-page .ans p,.quandan-page .ans li{
  letter-spacing: 0.02em;
  line-height: 1.6em;
  font-size:1rem;
  text-align: justify;
  color:#222;
}
.quandan-page .ans li{
  margin-bottom:1em;
}
.quandan-page strong{
  font-weight: 600;
}

