@import url(https://fonts.googleapis.com/css?family=Taviraj);
.tutorial-page ion-toolbar {
  --background: transparent;
  --border-color: transparent;
}

.tutorial-page .swiper-slide {
  display: block;
}

.tutorial-page .slide-title {
  margin-top: 2.8rem;
}

.tutorial-page .slide-image {
  max-height: 50%;
  max-width: 60%;
  margin: 36px 0;
}

.tutorial-page b {
  font-weight: 500;
}

.tutorial-page p {
  padding: 0 40px;
  font-size: 14px;
  line-height: 1.5;
  color: #60646B;
}

.tutorial-page p b {
  color: #000000;
}

.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

@charset "UTF-8";

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  min-height: 62vh;
  position: relative;
  text-align: center;
  background-color: #87a597;
}

.logo img {
  max-width: 40vw;
  position: absolute;
  top: 20px;
  left: 20px;
}

.list {
  margin-bottom: 0;
}

.plustitle {
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "Taviraj", "NSimSun", "noto serif tc", serif !important;
  position: relative;
  top: -0.5em;
  z-index: 10;
  text-shadow: 0 1px 5px rgba(0, 0, 0, .2);

}

.count {
  color: #ffffff;
  font-size: 1.2rem;
  margin-top: .3em;
  position: relative;
  top: -0.5em;
  z-index: 10;
  text-shadow: 0 1px 5px rgba(0, 0, 0, .2);
}

[IonContent] {
  height: 100vh;
}

.loginform {
  width: 400px;
  margin: 0 auto !important;
  padding: 1em;
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
  top: -11em;
  z-index: 10;
}

ion-checkbox {
  --size: 22px;
}

.ptxt {
  font-size: 15px !important;
  color: #555 !important;
}
	
.ilogo {
      width: 300px;
    height: 150px;
    position: absolute;
    z-index: 10;
    top: 30px;
    left: 30px;
  background-image: url('/assets/icon/m_logo.png');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 50%;
}

.main_logo {
  width: 100%;
  height: 62vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

/* line 2306, ../sass/screen.scss */
.main_logo:before {
  content: "";
  display: block;
  width: 100vw;
  height: 90vh;
  position: absolute;
  top: 0;
  left: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00c9fe+0,208ffa+99 */
  background: #819D8F;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, #8BBD99 0%, #819D8F 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8BBD99', endColorstr='#819D8F', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

/* line 2327, ../sass/screen.scss */
.main_logo:after {
  content: "";
  display: block;
  position: absolute;
  z-index: 6;
  width: 100%;
  height: 90vh;
}

/* line 2354, ../sass/screen.scss */
.bx1 {
  width: 12px;
  height: 12px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run1 21s linear infinite;
          animation: run1 21s linear infinite;
}

@-webkit-keyframes run1 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(392px, 181px, 0);
            transform: translate3d(392px, 181px, 0);
  }
  50% {
    opacity: -0.48814;
    -webkit-transform: translate3d(-1 px392px, -5 px181px, 0);
            transform: translate3d(-1 px392px, -5 px181px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(224px, 455px, 0);
            transform: translate3d(224px, 455px, 0);
  }
}

@keyframes run1 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(392px, 181px, 0);
            transform: translate3d(392px, 181px, 0);
  }
  50% {
    opacity: -0.48814;
    -webkit-transform: translate3d(-1 px392px, -5 px181px, 0);
            transform: translate3d(-1 px392px, -5 px181px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(224px, 455px, 0);
            transform: translate3d(224px, 455px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx2 {
  width: 15px;
  height: 15px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run2 33s linear infinite;
          animation: run2 33s linear infinite;
}

@-webkit-keyframes run2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(222px, 61px, 0);
            transform: translate3d(222px, 61px, 0);
  }
  50% {
    opacity: 0.06653;
    -webkit-transform: translate3d(-23 px222px, 0 px61px, 0);
            transform: translate3d(-23 px222px, 0 px61px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(273px, 293px, 0);
            transform: translate3d(273px, 293px, 0);
  }
}

@keyframes run2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(222px, 61px, 0);
            transform: translate3d(222px, 61px, 0);
  }
  50% {
    opacity: 0.06653;
    -webkit-transform: translate3d(-23 px222px, 0 px61px, 0);
            transform: translate3d(-23 px222px, 0 px61px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(273px, 293px, 0);
            transform: translate3d(273px, 293px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx3 {
  width: 21px;
  height: 21px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run3 20s linear infinite;
          animation: run3 20s linear infinite;
}

@-webkit-keyframes run3 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(225px, 89px, 0);
            transform: translate3d(225px, 89px, 0);
  }
  50% {
    opacity: 0.37096;
    -webkit-transform: translate3d(-12 px225px, -26 px89px, 0);
            transform: translate3d(-12 px225px, -26 px89px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(102px, 601px, 0);
            transform: translate3d(102px, 601px, 0);
  }
}

@keyframes run3 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(225px, 89px, 0);
            transform: translate3d(225px, 89px, 0);
  }
  50% {
    opacity: 0.37096;
    -webkit-transform: translate3d(-12 px225px, -26 px89px, 0);
            transform: translate3d(-12 px225px, -26 px89px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(102px, 601px, 0);
            transform: translate3d(102px, 601px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx4 {
  width: 24px;
  height: 24px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run4 22s linear infinite;
          animation: run4 22s linear infinite;
}

@-webkit-keyframes run4 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(339px, 31px, 0);
            transform: translate3d(339px, 31px, 0);
  }
  50% {
    opacity: -0.42994;
    -webkit-transform: translate3d(-29 px339px, -2 px31px, 0);
            transform: translate3d(-29 px339px, -2 px31px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(421px, 62px, 0);
            transform: translate3d(421px, 62px, 0);
  }
}

@keyframes run4 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(339px, 31px, 0);
            transform: translate3d(339px, 31px, 0);
  }
  50% {
    opacity: -0.42994;
    -webkit-transform: translate3d(-29 px339px, -2 px31px, 0);
            transform: translate3d(-29 px339px, -2 px31px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(421px, 62px, 0);
            transform: translate3d(421px, 62px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx5 {
  width: 15px;
  height: 15px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run5 20s linear infinite;
          animation: run5 20s linear infinite;
}

@-webkit-keyframes run5 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(436px, 170px, 0);
            transform: translate3d(436px, 170px, 0);
  }
  50% {
    opacity: -0.44999;
    -webkit-transform: translate3d(-9 px436px, -2 px170px, 0);
            transform: translate3d(-9 px436px, -2 px170px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(223px, -44px, 0);
            transform: translate3d(223px, -44px, 0);
  }
}

@keyframes run5 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(436px, 170px, 0);
            transform: translate3d(436px, 170px, 0);
  }
  50% {
    opacity: -0.44999;
    -webkit-transform: translate3d(-9 px436px, -2 px170px, 0);
            transform: translate3d(-9 px436px, -2 px170px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(223px, -44px, 0);
            transform: translate3d(223px, -44px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx6 {
  width: 23px;
  height: 23px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run6 36s linear infinite;
          animation: run6 36s linear infinite;
}

@-webkit-keyframes run6 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(22px, 1px, 0);
            transform: translate3d(22px, 1px, 0);
  }
  50% {
    opacity: 0.26005;
    -webkit-transform: translate3d(-25 px22px, -11 px1px, 0);
            transform: translate3d(-25 px22px, -11 px1px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-77px, 678px, 0);
            transform: translate3d(-77px, 678px, 0);
  }
}

@keyframes run6 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(22px, 1px, 0);
            transform: translate3d(22px, 1px, 0);
  }
  50% {
    opacity: 0.26005;
    -webkit-transform: translate3d(-25 px22px, -11 px1px, 0);
            transform: translate3d(-25 px22px, -11 px1px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-77px, 678px, 0);
            transform: translate3d(-77px, 678px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx7 {
  width: 9px;
  height: 9px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run7 24s linear infinite;
          animation: run7 24s linear infinite;
}

@-webkit-keyframes run7 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(214px, 325px, 0);
            transform: translate3d(214px, 325px, 0);
  }
  50% {
    opacity: 0.14745;
    -webkit-transform: translate3d(-22 px214px, -7 px325px, 0);
            transform: translate3d(-22 px214px, -7 px325px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(168px, -52px, 0);
            transform: translate3d(168px, -52px, 0);
  }
}

@keyframes run7 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(214px, 325px, 0);
            transform: translate3d(214px, 325px, 0);
  }
  50% {
    opacity: 0.14745;
    -webkit-transform: translate3d(-22 px214px, -7 px325px, 0);
            transform: translate3d(-22 px214px, -7 px325px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(168px, -52px, 0);
            transform: translate3d(168px, -52px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx8 {
  width: 12px;
  height: 12px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run8 20s linear infinite;
          animation: run8 20s linear infinite;
}

@-webkit-keyframes run8 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(272px, -14px, 0);
            transform: translate3d(272px, -14px, 0);
  }
  50% {
    opacity: -0.31648;
    -webkit-transform: translate3d(-15 px272px, -2 px-14px, 0);
            transform: translate3d(-15 px272px, -2 px-14px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(341px, 302px, 0);
            transform: translate3d(341px, 302px, 0);
  }
}

@keyframes run8 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(272px, -14px, 0);
            transform: translate3d(272px, -14px, 0);
  }
  50% {
    opacity: -0.31648;
    -webkit-transform: translate3d(-15 px272px, -2 px-14px, 0);
            transform: translate3d(-15 px272px, -2 px-14px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(341px, 302px, 0);
            transform: translate3d(341px, 302px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx9 {
  width: 4px;
  height: 4px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run9 27s linear infinite;
          animation: run9 27s linear infinite;
}

@-webkit-keyframes run9 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(210px, 90px, 0);
            transform: translate3d(210px, 90px, 0);
  }
  50% {
    opacity: -0.15663;
    -webkit-transform: translate3d(-7 px210px, -25 px90px, 0);
            transform: translate3d(-7 px210px, -25 px90px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(214px, -41px, 0);
            transform: translate3d(214px, -41px, 0);
  }
}

@keyframes run9 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(210px, 90px, 0);
            transform: translate3d(210px, 90px, 0);
  }
  50% {
    opacity: -0.15663;
    -webkit-transform: translate3d(-7 px210px, -25 px90px, 0);
            transform: translate3d(-7 px210px, -25 px90px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(214px, -41px, 0);
            transform: translate3d(214px, -41px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx10 {
  width: 15px;
  height: 15px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run10 28s linear infinite;
          animation: run10 28s linear infinite;
}

@-webkit-keyframes run10 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(272px, 141px, 0);
            transform: translate3d(272px, 141px, 0);
  }
  50% {
    opacity: 0.1879;
    -webkit-transform: translate3d(-14 px272px, -2 px141px, 0);
            transform: translate3d(-14 px272px, -2 px141px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(451px, 547px, 0);
            transform: translate3d(451px, 547px, 0);
  }
}

@keyframes run10 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(272px, 141px, 0);
            transform: translate3d(272px, 141px, 0);
  }
  50% {
    opacity: 0.1879;
    -webkit-transform: translate3d(-14 px272px, -2 px141px, 0);
            transform: translate3d(-14 px272px, -2 px141px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(451px, 547px, 0);
            transform: translate3d(451px, 547px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx11 {
  width: 11px;
  height: 11px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run11 39s linear infinite;
          animation: run11 39s linear infinite;
}

@-webkit-keyframes run11 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(385px, 136px, 0);
            transform: translate3d(385px, 136px, 0);
  }
  50% {
    opacity: 0.32252;
    -webkit-transform: translate3d(-18 px385px, -15 px136px, 0);
            transform: translate3d(-18 px385px, -15 px136px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(502px, 273px, 0);
            transform: translate3d(502px, 273px, 0);
  }
}

@keyframes run11 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(385px, 136px, 0);
            transform: translate3d(385px, 136px, 0);
  }
  50% {
    opacity: 0.32252;
    -webkit-transform: translate3d(-18 px385px, -15 px136px, 0);
            transform: translate3d(-18 px385px, -15 px136px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(502px, 273px, 0);
            transform: translate3d(502px, 273px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx12 {
  width: 23px;
  height: 23px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run12 18s linear infinite;
          animation: run12 18s linear infinite;
}

@-webkit-keyframes run12 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(61px, 153px, 0);
            transform: translate3d(61px, 153px, 0);
  }
  50% {
    opacity: 0.25778;
    -webkit-transform: translate3d(-1 px61px, -10 px153px, 0);
            transform: translate3d(-1 px61px, -10 px153px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(78px, 260px, 0);
            transform: translate3d(78px, 260px, 0);
  }
}

@keyframes run12 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(61px, 153px, 0);
            transform: translate3d(61px, 153px, 0);
  }
  50% {
    opacity: 0.25778;
    -webkit-transform: translate3d(-1 px61px, -10 px153px, 0);
            transform: translate3d(-1 px61px, -10 px153px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(78px, 260px, 0);
            transform: translate3d(78px, 260px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx13 {
  width: 2px;
  height: 2px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run13 18s linear infinite;
          animation: run13 18s linear infinite;
}

@-webkit-keyframes run13 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(39px, 116px, 0);
            transform: translate3d(39px, 116px, 0);
  }
  50% {
    opacity: 0.27979;
    -webkit-transform: translate3d(-4 px39px, -28 px116px, 0);
            transform: translate3d(-4 px39px, -28 px116px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(211px, 537px, 0);
            transform: translate3d(211px, 537px, 0);
  }
}

@keyframes run13 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(39px, 116px, 0);
            transform: translate3d(39px, 116px, 0);
  }
  50% {
    opacity: 0.27979;
    -webkit-transform: translate3d(-4 px39px, -28 px116px, 0);
            transform: translate3d(-4 px39px, -28 px116px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(211px, 537px, 0);
            transform: translate3d(211px, 537px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx14 {
  width: 8px;
  height: 8px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run14 40s linear infinite;
          animation: run14 40s linear infinite;
}

@-webkit-keyframes run14 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(50px, 19px, 0);
            transform: translate3d(50px, 19px, 0);
  }
  50% {
    opacity: -0.4742;
    -webkit-transform: translate3d(-5 px50px, -15 px19px, 0);
            transform: translate3d(-5 px50px, -15 px19px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(309px, 396px, 0);
            transform: translate3d(309px, 396px, 0);
  }
}

@keyframes run14 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(50px, 19px, 0);
            transform: translate3d(50px, 19px, 0);
  }
  50% {
    opacity: -0.4742;
    -webkit-transform: translate3d(-5 px50px, -15 px19px, 0);
            transform: translate3d(-5 px50px, -15 px19px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(309px, 396px, 0);
            transform: translate3d(309px, 396px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx15 {
  width: 22px;
  height: 22px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run15 38s linear infinite;
          animation: run15 38s linear infinite;
}

@-webkit-keyframes run15 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(219px, 134px, 0);
            transform: translate3d(219px, 134px, 0);
  }
  50% {
    opacity: -0.37353;
    -webkit-transform: translate3d(-27 px219px, -12 px134px, 0);
            transform: translate3d(-27 px219px, -12 px134px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(434px, 165px, 0);
            transform: translate3d(434px, 165px, 0);
  }
}

@keyframes run15 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(219px, 134px, 0);
            transform: translate3d(219px, 134px, 0);
  }
  50% {
    opacity: -0.37353;
    -webkit-transform: translate3d(-27 px219px, -12 px134px, 0);
            transform: translate3d(-27 px219px, -12 px134px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(434px, 165px, 0);
            transform: translate3d(434px, 165px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx16 {
  width: 3px;
  height: 3px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run16 20s linear infinite;
          animation: run16 20s linear infinite;
}

@-webkit-keyframes run16 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(281px, 321px, 0);
            transform: translate3d(281px, 321px, 0);
  }
  50% {
    opacity: 0.30621;
    -webkit-transform: translate3d(-4 px281px, -23 px321px, 0);
            transform: translate3d(-4 px281px, -23 px321px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(442px, 527px, 0);
            transform: translate3d(442px, 527px, 0);
  }
}

@keyframes run16 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(281px, 321px, 0);
            transform: translate3d(281px, 321px, 0);
  }
  50% {
    opacity: 0.30621;
    -webkit-transform: translate3d(-4 px281px, -23 px321px, 0);
            transform: translate3d(-4 px281px, -23 px321px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(442px, 527px, 0);
            transform: translate3d(442px, 527px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx17 {
  width: 9px;
  height: 9px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run17 28s linear infinite;
          animation: run17 28s linear infinite;
}

@-webkit-keyframes run17 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(157px, 13px, 0);
            transform: translate3d(157px, 13px, 0);
  }
  50% {
    opacity: 0.47651;
    -webkit-transform: translate3d(-29 px157px, -28 px13px, 0);
            transform: translate3d(-29 px157px, -28 px13px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(47px, 13px, 0);
            transform: translate3d(47px, 13px, 0);
  }
}

@keyframes run17 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(157px, 13px, 0);
            transform: translate3d(157px, 13px, 0);
  }
  50% {
    opacity: 0.47651;
    -webkit-transform: translate3d(-29 px157px, -28 px13px, 0);
            transform: translate3d(-29 px157px, -28 px13px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(47px, 13px, 0);
            transform: translate3d(47px, 13px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx18 {
  width: 18px;
  height: 18px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run18 30s linear infinite;
          animation: run18 30s linear infinite;
}

@-webkit-keyframes run18 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(277px, 341px, 0);
            transform: translate3d(277px, 341px, 0);
  }
  50% {
    opacity: -0.4166;
    -webkit-transform: translate3d(-9 px277px, -12 px341px, 0);
            transform: translate3d(-9 px277px, -12 px341px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-14px, -31px, 0);
            transform: translate3d(-14px, -31px, 0);
  }
}

@keyframes run18 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(277px, 341px, 0);
            transform: translate3d(277px, 341px, 0);
  }
  50% {
    opacity: -0.4166;
    -webkit-transform: translate3d(-9 px277px, -12 px341px, 0);
            transform: translate3d(-9 px277px, -12 px341px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-14px, -31px, 0);
            transform: translate3d(-14px, -31px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx19 {
  width: 22px;
  height: 22px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run19 25s linear infinite;
          animation: run19 25s linear infinite;
}

@-webkit-keyframes run19 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(201px, 11px, 0);
            transform: translate3d(201px, 11px, 0);
  }
  50% {
    opacity: 0.09839;
    -webkit-transform: translate3d(-9 px201px, -2 px11px, 0);
            transform: translate3d(-9 px201px, -2 px11px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(370px, 346px, 0);
            transform: translate3d(370px, 346px, 0);
  }
}

@keyframes run19 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(201px, 11px, 0);
            transform: translate3d(201px, 11px, 0);
  }
  50% {
    opacity: 0.09839;
    -webkit-transform: translate3d(-9 px201px, -2 px11px, 0);
            transform: translate3d(-9 px201px, -2 px11px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(370px, 346px, 0);
            transform: translate3d(370px, 346px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx20 {
  width: 18px;
  height: 18px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run20 27s linear infinite;
          animation: run20 27s linear infinite;
}

@-webkit-keyframes run20 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(418px, 66px, 0);
            transform: translate3d(418px, 66px, 0);
  }
  50% {
    opacity: 0.26352;
    -webkit-transform: translate3d(-26 px418px, -13 px66px, 0);
            transform: translate3d(-26 px418px, -13 px66px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-26px, 545px, 0);
            transform: translate3d(-26px, 545px, 0);
  }
}

@keyframes run20 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(418px, 66px, 0);
            transform: translate3d(418px, 66px, 0);
  }
  50% {
    opacity: 0.26352;
    -webkit-transform: translate3d(-26 px418px, -13 px66px, 0);
            transform: translate3d(-26 px418px, -13 px66px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-26px, 545px, 0);
            transform: translate3d(-26px, 545px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx21 {
  width: 14px;
  height: 14px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run21 16s linear infinite;
          animation: run21 16s linear infinite;
}

@-webkit-keyframes run21 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(160px, 10px, 0);
            transform: translate3d(160px, 10px, 0);
  }
  50% {
    opacity: -0.44448;
    -webkit-transform: translate3d(-11 px160px, -16 px10px, 0);
            transform: translate3d(-11 px160px, -16 px10px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(25px, -9px, 0);
            transform: translate3d(25px, -9px, 0);
  }
}

@keyframes run21 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(160px, 10px, 0);
            transform: translate3d(160px, 10px, 0);
  }
  50% {
    opacity: -0.44448;
    -webkit-transform: translate3d(-11 px160px, -16 px10px, 0);
            transform: translate3d(-11 px160px, -16 px10px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(25px, -9px, 0);
            transform: translate3d(25px, -9px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx22 {
  width: 20px;
  height: 20px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run22 23s linear infinite;
          animation: run22 23s linear infinite;
}

@-webkit-keyframes run22 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-45px, 87px, 0);
            transform: translate3d(-45px, 87px, 0);
  }
  50% {
    opacity: -0.06929;
    -webkit-transform: translate3d(-5 px-45px, 0 px87px, 0);
            transform: translate3d(-5 px-45px, 0 px87px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(404px, -71px, 0);
            transform: translate3d(404px, -71px, 0);
  }
}

@keyframes run22 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-45px, 87px, 0);
            transform: translate3d(-45px, 87px, 0);
  }
  50% {
    opacity: -0.06929;
    -webkit-transform: translate3d(-5 px-45px, 0 px87px, 0);
            transform: translate3d(-5 px-45px, 0 px87px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(404px, -71px, 0);
            transform: translate3d(404px, -71px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx23 {
  width: 21px;
  height: 21px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run23 40s linear infinite;
          animation: run23 40s linear infinite;
}

@-webkit-keyframes run23 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-17px, 249px, 0);
            transform: translate3d(-17px, 249px, 0);
  }
  50% {
    opacity: 0.11083;
    -webkit-transform: translate3d(-3 px-17px, -8 px249px, 0);
            transform: translate3d(-3 px-17px, -8 px249px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(327px, -65px, 0);
            transform: translate3d(327px, -65px, 0);
  }
}

@keyframes run23 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-17px, 249px, 0);
            transform: translate3d(-17px, 249px, 0);
  }
  50% {
    opacity: 0.11083;
    -webkit-transform: translate3d(-3 px-17px, -8 px249px, 0);
            transform: translate3d(-3 px-17px, -8 px249px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(327px, -65px, 0);
            transform: translate3d(327px, -65px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx24 {
  width: 8px;
  height: 8px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run24 31s linear infinite;
          animation: run24 31s linear infinite;
}

@-webkit-keyframes run24 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(150px, 94px, 0);
            transform: translate3d(150px, 94px, 0);
  }
  50% {
    opacity: -0.42237;
    -webkit-transform: translate3d(-24 px150px, -6 px94px, 0);
            transform: translate3d(-24 px150px, -6 px94px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(362px, 54px, 0);
            transform: translate3d(362px, 54px, 0);
  }
}

@keyframes run24 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(150px, 94px, 0);
            transform: translate3d(150px, 94px, 0);
  }
  50% {
    opacity: -0.42237;
    -webkit-transform: translate3d(-24 px150px, -6 px94px, 0);
            transform: translate3d(-24 px150px, -6 px94px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(362px, 54px, 0);
            transform: translate3d(362px, 54px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx25 {
  width: 9px;
  height: 9px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run25 38s linear infinite;
          animation: run25 38s linear infinite;
}

@-webkit-keyframes run25 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(120px, 70px, 0);
            transform: translate3d(120px, 70px, 0);
  }
  50% {
    opacity: -0.46256;
    -webkit-transform: translate3d(-21 px120px, -15 px70px, 0);
            transform: translate3d(-21 px120px, -15 px70px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2px, 47px, 0);
            transform: translate3d(-2px, 47px, 0);
  }
}

@keyframes run25 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(120px, 70px, 0);
            transform: translate3d(120px, 70px, 0);
  }
  50% {
    opacity: -0.46256;
    -webkit-transform: translate3d(-21 px120px, -15 px70px, 0);
            transform: translate3d(-21 px120px, -15 px70px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2px, 47px, 0);
            transform: translate3d(-2px, 47px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx26 {
  width: 11px;
  height: 11px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run26 30s linear infinite;
          animation: run26 30s linear infinite;
}

@-webkit-keyframes run26 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(431px, 38px, 0);
            transform: translate3d(431px, 38px, 0);
  }
  50% {
    opacity: 0.37216;
    -webkit-transform: translate3d(0 px431px, -10 px38px, 0);
            transform: translate3d(0 px431px, -10 px38px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(324px, 272px, 0);
            transform: translate3d(324px, 272px, 0);
  }
}

@keyframes run26 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(431px, 38px, 0);
            transform: translate3d(431px, 38px, 0);
  }
  50% {
    opacity: 0.37216;
    -webkit-transform: translate3d(0 px431px, -10 px38px, 0);
            transform: translate3d(0 px431px, -10 px38px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(324px, 272px, 0);
            transform: translate3d(324px, 272px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx27 {
  width: 20px;
  height: 20px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run27 20s linear infinite;
          animation: run27 20s linear infinite;
}

@-webkit-keyframes run27 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(60px, 84px, 0);
            transform: translate3d(60px, 84px, 0);
  }
  50% {
    opacity: -0.4111;
    -webkit-transform: translate3d(-25 px60px, -15 px84px, 0);
            transform: translate3d(-25 px60px, -15 px84px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(435px, 543px, 0);
            transform: translate3d(435px, 543px, 0);
  }
}

@keyframes run27 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(60px, 84px, 0);
            transform: translate3d(60px, 84px, 0);
  }
  50% {
    opacity: -0.4111;
    -webkit-transform: translate3d(-25 px60px, -15 px84px, 0);
            transform: translate3d(-25 px60px, -15 px84px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(435px, 543px, 0);
            transform: translate3d(435px, 543px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx28 {
  width: 14px;
  height: 14px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run28 37s linear infinite;
          animation: run28 37s linear infinite;
}

@-webkit-keyframes run28 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(251px, -27px, 0);
            transform: translate3d(251px, -27px, 0);
  }
  50% {
    opacity: 0.10353;
    -webkit-transform: translate3d(-21 px251px, -13 px-27px, 0);
            transform: translate3d(-21 px251px, -13 px-27px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-77px, 208px, 0);
            transform: translate3d(-77px, 208px, 0);
  }
}

@keyframes run28 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(251px, -27px, 0);
            transform: translate3d(251px, -27px, 0);
  }
  50% {
    opacity: 0.10353;
    -webkit-transform: translate3d(-21 px251px, -13 px-27px, 0);
            transform: translate3d(-21 px251px, -13 px-27px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-77px, 208px, 0);
            transform: translate3d(-77px, 208px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx29 {
  width: 1px;
  height: 1px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run29 38s linear infinite;
          animation: run29 38s linear infinite;
}

@-webkit-keyframes run29 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(353px, 262px, 0);
            transform: translate3d(353px, 262px, 0);
  }
  50% {
    opacity: 0.1375;
    -webkit-transform: translate3d(-3 px353px, -19 px262px, 0);
            transform: translate3d(-3 px353px, -19 px262px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(273px, -18px, 0);
            transform: translate3d(273px, -18px, 0);
  }
}

@keyframes run29 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(353px, 262px, 0);
            transform: translate3d(353px, 262px, 0);
  }
  50% {
    opacity: 0.1375;
    -webkit-transform: translate3d(-3 px353px, -19 px262px, 0);
            transform: translate3d(-3 px353px, -19 px262px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(273px, -18px, 0);
            transform: translate3d(273px, -18px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx30 {
  width: 14px;
  height: 14px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run30 36s linear infinite;
          animation: run30 36s linear infinite;
}

@-webkit-keyframes run30 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(247px, 198px, 0);
            transform: translate3d(247px, 198px, 0);
  }
  50% {
    opacity: -0.38851;
    -webkit-transform: translate3d(-6 px247px, -20 px198px, 0);
            transform: translate3d(-6 px247px, -20 px198px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(12px, 284px, 0);
            transform: translate3d(12px, 284px, 0);
  }
}

@keyframes run30 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(247px, 198px, 0);
            transform: translate3d(247px, 198px, 0);
  }
  50% {
    opacity: -0.38851;
    -webkit-transform: translate3d(-6 px247px, -20 px198px, 0);
            transform: translate3d(-6 px247px, -20 px198px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(12px, 284px, 0);
            transform: translate3d(12px, 284px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx31 {
  width: 22px;
  height: 22px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run31 24s linear infinite;
          animation: run31 24s linear infinite;
}

@-webkit-keyframes run31 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(222px, 86px, 0);
            transform: translate3d(222px, 86px, 0);
  }
  50% {
    opacity: -0.01222;
    -webkit-transform: translate3d(-26 px222px, -27 px86px, 0);
            transform: translate3d(-26 px222px, -27 px86px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(51px, 204px, 0);
            transform: translate3d(51px, 204px, 0);
  }
}

@keyframes run31 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(222px, 86px, 0);
            transform: translate3d(222px, 86px, 0);
  }
  50% {
    opacity: -0.01222;
    -webkit-transform: translate3d(-26 px222px, -27 px86px, 0);
            transform: translate3d(-26 px222px, -27 px86px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(51px, 204px, 0);
            transform: translate3d(51px, 204px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx32 {
  width: 12px;
  height: 12px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run32 39s linear infinite;
          animation: run32 39s linear infinite;
}

@-webkit-keyframes run32 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-26px, 270px, 0);
            transform: translate3d(-26px, 270px, 0);
  }
  50% {
    opacity: -0.29778;
    -webkit-transform: translate3d(-29 px-26px, -17 px270px, 0);
            transform: translate3d(-29 px-26px, -17 px270px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(423px, 412px, 0);
            transform: translate3d(423px, 412px, 0);
  }
}

@keyframes run32 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-26px, 270px, 0);
            transform: translate3d(-26px, 270px, 0);
  }
  50% {
    opacity: -0.29778;
    -webkit-transform: translate3d(-29 px-26px, -17 px270px, 0);
            transform: translate3d(-29 px-26px, -17 px270px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(423px, 412px, 0);
            transform: translate3d(423px, 412px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx33 {
  width: 20px;
  height: 20px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run33 39s linear infinite;
          animation: run33 39s linear infinite;
}

@-webkit-keyframes run33 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(263px, 119px, 0);
            transform: translate3d(263px, 119px, 0);
  }
  50% {
    opacity: -0.31704;
    -webkit-transform: translate3d(-13 px263px, -18 px119px, 0);
            transform: translate3d(-13 px263px, -18 px119px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(80px, 224px, 0);
            transform: translate3d(80px, 224px, 0);
  }
}

@keyframes run33 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(263px, 119px, 0);
            transform: translate3d(263px, 119px, 0);
  }
  50% {
    opacity: -0.31704;
    -webkit-transform: translate3d(-13 px263px, -18 px119px, 0);
            transform: translate3d(-13 px263px, -18 px119px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(80px, 224px, 0);
            transform: translate3d(80px, 224px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx34 {
  width: 10px;
  height: 10px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run34 37s linear infinite;
          animation: run34 37s linear infinite;
}

@-webkit-keyframes run34 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(210px, 122px, 0);
            transform: translate3d(210px, 122px, 0);
  }
  50% {
    opacity: -0.06628;
    -webkit-transform: translate3d(-8 px210px, -7 px122px, 0);
            transform: translate3d(-8 px210px, -7 px122px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(250px, 276px, 0);
            transform: translate3d(250px, 276px, 0);
  }
}

@keyframes run34 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(210px, 122px, 0);
            transform: translate3d(210px, 122px, 0);
  }
  50% {
    opacity: -0.06628;
    -webkit-transform: translate3d(-8 px210px, -7 px122px, 0);
            transform: translate3d(-8 px210px, -7 px122px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(250px, 276px, 0);
            transform: translate3d(250px, 276px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx35 {
  width: 20px;
  height: 20px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run35 19s linear infinite;
          animation: run35 19s linear infinite;
}

@-webkit-keyframes run35 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(30px, 197px, 0);
            transform: translate3d(30px, 197px, 0);
  }
  50% {
    opacity: -0.0089;
    -webkit-transform: translate3d(-4 px30px, -26 px197px, 0);
            transform: translate3d(-4 px30px, -26 px197px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-85px, 603px, 0);
            transform: translate3d(-85px, 603px, 0);
  }
}

@keyframes run35 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(30px, 197px, 0);
            transform: translate3d(30px, 197px, 0);
  }
  50% {
    opacity: -0.0089;
    -webkit-transform: translate3d(-4 px30px, -26 px197px, 0);
            transform: translate3d(-4 px30px, -26 px197px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-85px, 603px, 0);
            transform: translate3d(-85px, 603px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx36 {
  width: 2px;
  height: 2px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run36 24s linear infinite;
          animation: run36 24s linear infinite;
}

@-webkit-keyframes run36 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(277px, -7px, 0);
            transform: translate3d(277px, -7px, 0);
  }
  50% {
    opacity: -0.27361;
    -webkit-transform: translate3d(-8 px277px, -1 px-7px, 0);
            transform: translate3d(-8 px277px, -1 px-7px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(227px, 144px, 0);
            transform: translate3d(227px, 144px, 0);
  }
}

@keyframes run36 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(277px, -7px, 0);
            transform: translate3d(277px, -7px, 0);
  }
  50% {
    opacity: -0.27361;
    -webkit-transform: translate3d(-8 px277px, -1 px-7px, 0);
            transform: translate3d(-8 px277px, -1 px-7px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(227px, 144px, 0);
            transform: translate3d(227px, 144px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx37 {
  width: 5px;
  height: 5px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run37 27s linear infinite;
          animation: run37 27s linear infinite;
}

@-webkit-keyframes run37 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-38px, 226px, 0);
            transform: translate3d(-38px, 226px, 0);
  }
  50% {
    opacity: 0.41348;
    -webkit-transform: translate3d(-1 px-38px, -13 px226px, 0);
            transform: translate3d(-1 px-38px, -13 px226px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(402px, 512px, 0);
            transform: translate3d(402px, 512px, 0);
  }
}

@keyframes run37 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-38px, 226px, 0);
            transform: translate3d(-38px, 226px, 0);
  }
  50% {
    opacity: 0.41348;
    -webkit-transform: translate3d(-1 px-38px, -13 px226px, 0);
            transform: translate3d(-1 px-38px, -13 px226px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(402px, 512px, 0);
            transform: translate3d(402px, 512px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx38 {
  width: 4px;
  height: 4px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run38 28s linear infinite;
          animation: run38 28s linear infinite;
}

@-webkit-keyframes run38 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(166px, 302px, 0);
            transform: translate3d(166px, 302px, 0);
  }
  50% {
    opacity: -0.22564;
    -webkit-transform: translate3d(-10 px166px, -22 px302px, 0);
            transform: translate3d(-10 px166px, -22 px302px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(329px, 34px, 0);
            transform: translate3d(329px, 34px, 0);
  }
}

@keyframes run38 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(166px, 302px, 0);
            transform: translate3d(166px, 302px, 0);
  }
  50% {
    opacity: -0.22564;
    -webkit-transform: translate3d(-10 px166px, -22 px302px, 0);
            transform: translate3d(-10 px166px, -22 px302px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(329px, 34px, 0);
            transform: translate3d(329px, 34px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx39 {
  width: 10px;
  height: 10px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run39 20s linear infinite;
          animation: run39 20s linear infinite;
}

@-webkit-keyframes run39 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(432px, 219px, 0);
            transform: translate3d(432px, 219px, 0);
  }
  50% {
    opacity: 0.46324;
    -webkit-transform: translate3d(-18 px432px, -7 px219px, 0);
            transform: translate3d(-18 px432px, -7 px219px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(149px, 554px, 0);
            transform: translate3d(149px, 554px, 0);
  }
}

@keyframes run39 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(432px, 219px, 0);
            transform: translate3d(432px, 219px, 0);
  }
  50% {
    opacity: 0.46324;
    -webkit-transform: translate3d(-18 px432px, -7 px219px, 0);
            transform: translate3d(-18 px432px, -7 px219px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(149px, 554px, 0);
            transform: translate3d(149px, 554px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx40 {
  width: 2px;
  height: 2px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run40 19s linear infinite;
          animation: run40 19s linear infinite;
}

@-webkit-keyframes run40 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(381px, -30px, 0);
            transform: translate3d(381px, -30px, 0);
  }
  50% {
    opacity: 0.31324;
    -webkit-transform: translate3d(-4 px381px, -18 px-30px, 0);
            transform: translate3d(-4 px381px, -18 px-30px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-62px, 40px, 0);
            transform: translate3d(-62px, 40px, 0);
  }
}

@keyframes run40 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(381px, -30px, 0);
            transform: translate3d(381px, -30px, 0);
  }
  50% {
    opacity: 0.31324;
    -webkit-transform: translate3d(-4 px381px, -18 px-30px, 0);
            transform: translate3d(-4 px381px, -18 px-30px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-62px, 40px, 0);
            transform: translate3d(-62px, 40px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx41 {
  width: 9px;
  height: 9px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run41 17s linear infinite;
          animation: run41 17s linear infinite;
}

@-webkit-keyframes run41 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(324px, 151px, 0);
            transform: translate3d(324px, 151px, 0);
  }
  50% {
    opacity: 0.17675;
    -webkit-transform: translate3d(-3 px324px, -17 px151px, 0);
            transform: translate3d(-3 px324px, -17 px151px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(546px, 668px, 0);
            transform: translate3d(546px, 668px, 0);
  }
}

@keyframes run41 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(324px, 151px, 0);
            transform: translate3d(324px, 151px, 0);
  }
  50% {
    opacity: 0.17675;
    -webkit-transform: translate3d(-3 px324px, -17 px151px, 0);
            transform: translate3d(-3 px324px, -17 px151px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(546px, 668px, 0);
            transform: translate3d(546px, 668px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx42 {
  width: 3px;
  height: 3px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run42 35s linear infinite;
          animation: run42 35s linear infinite;
}

@-webkit-keyframes run42 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(383px, 236px, 0);
            transform: translate3d(383px, 236px, 0);
  }
  50% {
    opacity: -0.33542;
    -webkit-transform: translate3d(-11 px383px, -29 px236px, 0);
            transform: translate3d(-11 px383px, -29 px236px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(540px, 280px, 0);
            transform: translate3d(540px, 280px, 0);
  }
}

@keyframes run42 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(383px, 236px, 0);
            transform: translate3d(383px, 236px, 0);
  }
  50% {
    opacity: -0.33542;
    -webkit-transform: translate3d(-11 px383px, -29 px236px, 0);
            transform: translate3d(-11 px383px, -29 px236px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(540px, 280px, 0);
            transform: translate3d(540px, 280px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx43 {
  width: 9px;
  height: 9px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run43 34s linear infinite;
          animation: run43 34s linear infinite;
}

@-webkit-keyframes run43 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(187px, 100px, 0);
            transform: translate3d(187px, 100px, 0);
  }
  50% {
    opacity: 0.20576;
    -webkit-transform: translate3d(-3 px187px, -8 px100px, 0);
            transform: translate3d(-3 px187px, -8 px100px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-80px, 660px, 0);
            transform: translate3d(-80px, 660px, 0);
  }
}

@keyframes run43 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(187px, 100px, 0);
            transform: translate3d(187px, 100px, 0);
  }
  50% {
    opacity: 0.20576;
    -webkit-transform: translate3d(-3 px187px, -8 px100px, 0);
            transform: translate3d(-3 px187px, -8 px100px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-80px, 660px, 0);
            transform: translate3d(-80px, 660px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx44 {
  width: 7px;
  height: 7px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run44 16s linear infinite;
          animation: run44 16s linear infinite;
}

@-webkit-keyframes run44 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(8px, 98px, 0);
            transform: translate3d(8px, 98px, 0);
  }
  50% {
    opacity: 0.19125;
    -webkit-transform: translate3d(-9 px8px, -14 px98px, 0);
            transform: translate3d(-9 px8px, -14 px98px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(37px, 431px, 0);
            transform: translate3d(37px, 431px, 0);
  }
}

@keyframes run44 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(8px, 98px, 0);
            transform: translate3d(8px, 98px, 0);
  }
  50% {
    opacity: 0.19125;
    -webkit-transform: translate3d(-9 px8px, -14 px98px, 0);
            transform: translate3d(-9 px8px, -14 px98px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(37px, 431px, 0);
            transform: translate3d(37px, 431px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx45 {
  width: 17px;
  height: 17px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run45 38s linear infinite;
          animation: run45 38s linear infinite;
}

@-webkit-keyframes run45 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(190px, 337px, 0);
            transform: translate3d(190px, 337px, 0);
  }
  50% {
    opacity: 0.17822;
    -webkit-transform: translate3d(-19 px190px, -27 px337px, 0);
            transform: translate3d(-19 px190px, -27 px337px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(225px, 6px, 0);
            transform: translate3d(225px, 6px, 0);
  }
}

@keyframes run45 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(190px, 337px, 0);
            transform: translate3d(190px, 337px, 0);
  }
  50% {
    opacity: 0.17822;
    -webkit-transform: translate3d(-19 px190px, -27 px337px, 0);
            transform: translate3d(-19 px190px, -27 px337px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(225px, 6px, 0);
            transform: translate3d(225px, 6px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx46 {
  width: 8px;
  height: 8px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run46 17s linear infinite;
          animation: run46 17s linear infinite;
}

@-webkit-keyframes run46 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(414px, -38px, 0);
            transform: translate3d(414px, -38px, 0);
  }
  50% {
    opacity: 0.38822;
    -webkit-transform: translate3d(-15 px414px, -24 px-38px, 0);
            transform: translate3d(-15 px414px, -24 px-38px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(178px, 191px, 0);
            transform: translate3d(178px, 191px, 0);
  }
}

@keyframes run46 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(414px, -38px, 0);
            transform: translate3d(414px, -38px, 0);
  }
  50% {
    opacity: 0.38822;
    -webkit-transform: translate3d(-15 px414px, -24 px-38px, 0);
            transform: translate3d(-15 px414px, -24 px-38px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(178px, 191px, 0);
            transform: translate3d(178px, 191px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx47 {
  width: 15px;
  height: 15px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run47 38s linear infinite;
          animation: run47 38s linear infinite;
}

@-webkit-keyframes run47 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(99px, -34px, 0);
            transform: translate3d(99px, -34px, 0);
  }
  50% {
    opacity: -0.08102;
    -webkit-transform: translate3d(-27 px99px, -22 px-34px, 0);
            transform: translate3d(-27 px99px, -22 px-34px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(68px, 443px, 0);
            transform: translate3d(68px, 443px, 0);
  }
}

@keyframes run47 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(99px, -34px, 0);
            transform: translate3d(99px, -34px, 0);
  }
  50% {
    opacity: -0.08102;
    -webkit-transform: translate3d(-27 px99px, -22 px-34px, 0);
            transform: translate3d(-27 px99px, -22 px-34px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(68px, 443px, 0);
            transform: translate3d(68px, 443px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx48 {
  width: 21px;
  height: 21px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run48 20s linear infinite;
          animation: run48 20s linear infinite;
}

@-webkit-keyframes run48 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(80px, 104px, 0);
            transform: translate3d(80px, 104px, 0);
  }
  50% {
    opacity: 0.42564;
    -webkit-transform: translate3d(-15 px80px, -28 px104px, 0);
            transform: translate3d(-15 px80px, -28 px104px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(550px, 490px, 0);
            transform: translate3d(550px, 490px, 0);
  }
}

@keyframes run48 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(80px, 104px, 0);
            transform: translate3d(80px, 104px, 0);
  }
  50% {
    opacity: 0.42564;
    -webkit-transform: translate3d(-15 px80px, -28 px104px, 0);
            transform: translate3d(-15 px80px, -28 px104px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(550px, 490px, 0);
            transform: translate3d(550px, 490px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx49 {
  width: 18px;
  height: 18px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run49 37s linear infinite;
          animation: run49 37s linear infinite;
}

@-webkit-keyframes run49 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(73px, 171px, 0);
            transform: translate3d(73px, 171px, 0);
  }
  50% {
    opacity: 0.44675;
    -webkit-transform: translate3d(-23 px73px, -8 px171px, 0);
            transform: translate3d(-23 px73px, -8 px171px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(554px, 642px, 0);
            transform: translate3d(554px, 642px, 0);
  }
}

@keyframes run49 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(73px, 171px, 0);
            transform: translate3d(73px, 171px, 0);
  }
  50% {
    opacity: 0.44675;
    -webkit-transform: translate3d(-23 px73px, -8 px171px, 0);
            transform: translate3d(-23 px73px, -8 px171px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(554px, 642px, 0);
            transform: translate3d(554px, 642px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx50 {
  width: 3px;
  height: 3px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run50 18s linear infinite;
          animation: run50 18s linear infinite;
}

@-webkit-keyframes run50 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(381px, 317px, 0);
            transform: translate3d(381px, 317px, 0);
  }
  50% {
    opacity: 0.04544;
    -webkit-transform: translate3d(-6 px381px, -4 px317px, 0);
            transform: translate3d(-6 px381px, -4 px317px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(106px, 625px, 0);
            transform: translate3d(106px, 625px, 0);
  }
}

@keyframes run50 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(381px, 317px, 0);
            transform: translate3d(381px, 317px, 0);
  }
  50% {
    opacity: 0.04544;
    -webkit-transform: translate3d(-6 px381px, -4 px317px, 0);
            transform: translate3d(-6 px381px, -4 px317px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(106px, 625px, 0);
            transform: translate3d(106px, 625px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx51 {
  width: 13px;
  height: 13px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run51 20s linear infinite;
          animation: run51 20s linear infinite;
}

@-webkit-keyframes run51 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(420px, 292px, 0);
            transform: translate3d(420px, 292px, 0);
  }
  50% {
    opacity: -0.33006;
    -webkit-transform: translate3d(-15 px420px, -9 px292px, 0);
            transform: translate3d(-15 px420px, -9 px292px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(256px, 504px, 0);
            transform: translate3d(256px, 504px, 0);
  }
}

@keyframes run51 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(420px, 292px, 0);
            transform: translate3d(420px, 292px, 0);
  }
  50% {
    opacity: -0.33006;
    -webkit-transform: translate3d(-15 px420px, -9 px292px, 0);
            transform: translate3d(-15 px420px, -9 px292px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(256px, 504px, 0);
            transform: translate3d(256px, 504px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx52 {
  width: 21px;
  height: 21px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run52 18s linear infinite;
          animation: run52 18s linear infinite;
}

@-webkit-keyframes run52 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(235px, 107px, 0);
            transform: translate3d(235px, 107px, 0);
  }
  50% {
    opacity: -0.45314;
    -webkit-transform: translate3d(-21 px235px, -9 px107px, 0);
            transform: translate3d(-21 px235px, -9 px107px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(304px, 0px, 0);
            transform: translate3d(304px, 0px, 0);
  }
}

@keyframes run52 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(235px, 107px, 0);
            transform: translate3d(235px, 107px, 0);
  }
  50% {
    opacity: -0.45314;
    -webkit-transform: translate3d(-21 px235px, -9 px107px, 0);
            transform: translate3d(-21 px235px, -9 px107px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(304px, 0px, 0);
            transform: translate3d(304px, 0px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx53 {
  width: 23px;
  height: 23px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run53 20s linear infinite;
          animation: run53 20s linear infinite;
}

@-webkit-keyframes run53 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(130px, 189px, 0);
            transform: translate3d(130px, 189px, 0);
  }
  50% {
    opacity: 0.3356;
    -webkit-transform: translate3d(-24 px130px, -1 px189px, 0);
            transform: translate3d(-24 px130px, -1 px189px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-62px, 299px, 0);
            transform: translate3d(-62px, 299px, 0);
  }
}

@keyframes run53 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(130px, 189px, 0);
            transform: translate3d(130px, 189px, 0);
  }
  50% {
    opacity: 0.3356;
    -webkit-transform: translate3d(-24 px130px, -1 px189px, 0);
            transform: translate3d(-24 px130px, -1 px189px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-62px, 299px, 0);
            transform: translate3d(-62px, 299px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx54 {
  width: 3px;
  height: 3px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run54 31s linear infinite;
          animation: run54 31s linear infinite;
}

@-webkit-keyframes run54 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(91px, 123px, 0);
            transform: translate3d(91px, 123px, 0);
  }
  50% {
    opacity: -0.11544;
    -webkit-transform: translate3d(0 px91px, -15 px123px, 0);
            transform: translate3d(0 px91px, -15 px123px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-25px, 481px, 0);
            transform: translate3d(-25px, 481px, 0);
  }
}

@keyframes run54 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(91px, 123px, 0);
            transform: translate3d(91px, 123px, 0);
  }
  50% {
    opacity: -0.11544;
    -webkit-transform: translate3d(0 px91px, -15 px123px, 0);
            transform: translate3d(0 px91px, -15 px123px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-25px, 481px, 0);
            transform: translate3d(-25px, 481px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx55 {
  width: 23px;
  height: 23px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run55 16s linear infinite;
          animation: run55 16s linear infinite;
}

@-webkit-keyframes run55 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(417px, 41px, 0);
            transform: translate3d(417px, 41px, 0);
  }
  50% {
    opacity: 0.31668;
    -webkit-transform: translate3d(-21 px417px, -17 px41px, 0);
            transform: translate3d(-21 px417px, -17 px41px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(586px, 171px, 0);
            transform: translate3d(586px, 171px, 0);
  }
}

@keyframes run55 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(417px, 41px, 0);
            transform: translate3d(417px, 41px, 0);
  }
  50% {
    opacity: 0.31668;
    -webkit-transform: translate3d(-21 px417px, -17 px41px, 0);
            transform: translate3d(-21 px417px, -17 px41px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(586px, 171px, 0);
            transform: translate3d(586px, 171px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx56 {
  width: 7px;
  height: 7px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run56 31s linear infinite;
          animation: run56 31s linear infinite;
}

@-webkit-keyframes run56 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(246px, 309px, 0);
            transform: translate3d(246px, 309px, 0);
  }
  50% {
    opacity: -0.2606;
    -webkit-transform: translate3d(-2 px246px, -12 px309px, 0);
            transform: translate3d(-2 px246px, -12 px309px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-35px, 243px, 0);
            transform: translate3d(-35px, 243px, 0);
  }
}

@keyframes run56 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(246px, 309px, 0);
            transform: translate3d(246px, 309px, 0);
  }
  50% {
    opacity: -0.2606;
    -webkit-transform: translate3d(-2 px246px, -12 px309px, 0);
            transform: translate3d(-2 px246px, -12 px309px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-35px, 243px, 0);
            transform: translate3d(-35px, 243px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx57 {
  width: 11px;
  height: 11px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run57 16s linear infinite;
          animation: run57 16s linear infinite;
}

@-webkit-keyframes run57 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(304px, 139px, 0);
            transform: translate3d(304px, 139px, 0);
  }
  50% {
    opacity: -0.37327;
    -webkit-transform: translate3d(-13 px304px, -28 px139px, 0);
            transform: translate3d(-13 px304px, -28 px139px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(12px, 208px, 0);
            transform: translate3d(12px, 208px, 0);
  }
}

@keyframes run57 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(304px, 139px, 0);
            transform: translate3d(304px, 139px, 0);
  }
  50% {
    opacity: -0.37327;
    -webkit-transform: translate3d(-13 px304px, -28 px139px, 0);
            transform: translate3d(-13 px304px, -28 px139px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(12px, 208px, 0);
            transform: translate3d(12px, 208px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx58 {
  width: 15px;
  height: 15px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run58 33s linear infinite;
          animation: run58 33s linear infinite;
}

@-webkit-keyframes run58 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(89px, 328px, 0);
            transform: translate3d(89px, 328px, 0);
  }
  50% {
    opacity: -0.39174;
    -webkit-transform: translate3d(-27 px89px, -7 px328px, 0);
            transform: translate3d(-27 px89px, -7 px328px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(311px, 250px, 0);
            transform: translate3d(311px, 250px, 0);
  }
}

@keyframes run58 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(89px, 328px, 0);
            transform: translate3d(89px, 328px, 0);
  }
  50% {
    opacity: -0.39174;
    -webkit-transform: translate3d(-27 px89px, -7 px328px, 0);
            transform: translate3d(-27 px89px, -7 px328px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(311px, 250px, 0);
            transform: translate3d(311px, 250px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx59 {
  width: 7px;
  height: 7px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run59 32s linear infinite;
          animation: run59 32s linear infinite;
}

@-webkit-keyframes run59 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(359px, 46px, 0);
            transform: translate3d(359px, 46px, 0);
  }
  50% {
    opacity: -0.3177;
    -webkit-transform: translate3d(-22 px359px, -21 px46px, 0);
            transform: translate3d(-22 px359px, -21 px46px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(91px, 210px, 0);
            transform: translate3d(91px, 210px, 0);
  }
}

@keyframes run59 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(359px, 46px, 0);
            transform: translate3d(359px, 46px, 0);
  }
  50% {
    opacity: -0.3177;
    -webkit-transform: translate3d(-22 px359px, -21 px46px, 0);
            transform: translate3d(-22 px359px, -21 px46px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(91px, 210px, 0);
            transform: translate3d(91px, 210px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx60 {
  width: 17px;
  height: 17px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run60 35s linear infinite;
          animation: run60 35s linear infinite;
}

@-webkit-keyframes run60 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(153px, 176px, 0);
            transform: translate3d(153px, 176px, 0);
  }
  50% {
    opacity: -0.1447;
    -webkit-transform: translate3d(-14 px153px, -16 px176px, 0);
            transform: translate3d(-14 px153px, -16 px176px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(213px, 335px, 0);
            transform: translate3d(213px, 335px, 0);
  }
}

@keyframes run60 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(153px, 176px, 0);
            transform: translate3d(153px, 176px, 0);
  }
  50% {
    opacity: -0.1447;
    -webkit-transform: translate3d(-14 px153px, -16 px176px, 0);
            transform: translate3d(-14 px153px, -16 px176px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(213px, 335px, 0);
            transform: translate3d(213px, 335px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx61 {
  width: 12px;
  height: 12px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run61 24s linear infinite;
          animation: run61 24s linear infinite;
}

@-webkit-keyframes run61 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(282px, 279px, 0);
            transform: translate3d(282px, 279px, 0);
  }
  50% {
    opacity: 0.04146;
    -webkit-transform: translate3d(-10 px282px, -14 px279px, 0);
            transform: translate3d(-10 px282px, -14 px279px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(128px, 611px, 0);
            transform: translate3d(128px, 611px, 0);
  }
}

@keyframes run61 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(282px, 279px, 0);
            transform: translate3d(282px, 279px, 0);
  }
  50% {
    opacity: 0.04146;
    -webkit-transform: translate3d(-10 px282px, -14 px279px, 0);
            transform: translate3d(-10 px282px, -14 px279px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(128px, 611px, 0);
            transform: translate3d(128px, 611px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx62 {
  width: 16px;
  height: 16px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run62 39s linear infinite;
          animation: run62 39s linear infinite;
}

@-webkit-keyframes run62 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(342px, -3px, 0);
            transform: translate3d(342px, -3px, 0);
  }
  50% {
    opacity: -0.43513;
    -webkit-transform: translate3d(-11 px342px, -12 px-3px, 0);
            transform: translate3d(-11 px342px, -12 px-3px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(409px, 481px, 0);
            transform: translate3d(409px, 481px, 0);
  }
}

@keyframes run62 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(342px, -3px, 0);
            transform: translate3d(342px, -3px, 0);
  }
  50% {
    opacity: -0.43513;
    -webkit-transform: translate3d(-11 px342px, -12 px-3px, 0);
            transform: translate3d(-11 px342px, -12 px-3px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(409px, 481px, 0);
            transform: translate3d(409px, 481px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx63 {
  width: 7px;
  height: 7px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run63 30s linear infinite;
          animation: run63 30s linear infinite;
}

@-webkit-keyframes run63 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(272px, 331px, 0);
            transform: translate3d(272px, 331px, 0);
  }
  50% {
    opacity: -0.21568;
    -webkit-transform: translate3d(-18 px272px, -21 px331px, 0);
            transform: translate3d(-18 px272px, -21 px331px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(113px, 329px, 0);
            transform: translate3d(113px, 329px, 0);
  }
}

@keyframes run63 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(272px, 331px, 0);
            transform: translate3d(272px, 331px, 0);
  }
  50% {
    opacity: -0.21568;
    -webkit-transform: translate3d(-18 px272px, -21 px331px, 0);
            transform: translate3d(-18 px272px, -21 px331px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(113px, 329px, 0);
            transform: translate3d(113px, 329px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx64 {
  width: 16px;
  height: 16px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run64 34s linear infinite;
          animation: run64 34s linear infinite;
}

@-webkit-keyframes run64 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(371px, 148px, 0);
            transform: translate3d(371px, 148px, 0);
  }
  50% {
    opacity: 0.27213;
    -webkit-transform: translate3d(-8 px371px, -28 px148px, 0);
            transform: translate3d(-8 px371px, -28 px148px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-3px, 399px, 0);
            transform: translate3d(-3px, 399px, 0);
  }
}

@keyframes run64 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(371px, 148px, 0);
            transform: translate3d(371px, 148px, 0);
  }
  50% {
    opacity: 0.27213;
    -webkit-transform: translate3d(-8 px371px, -28 px148px, 0);
            transform: translate3d(-8 px371px, -28 px148px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-3px, 399px, 0);
            transform: translate3d(-3px, 399px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx65 {
  width: 1px;
  height: 1px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run65 34s linear infinite;
          animation: run65 34s linear infinite;
}

@-webkit-keyframes run65 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(336px, 250px, 0);
            transform: translate3d(336px, 250px, 0);
  }
  50% {
    opacity: -0.25104;
    -webkit-transform: translate3d(-9 px336px, -14 px250px, 0);
            transform: translate3d(-9 px336px, -14 px250px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(173px, 248px, 0);
            transform: translate3d(173px, 248px, 0);
  }
}

@keyframes run65 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(336px, 250px, 0);
            transform: translate3d(336px, 250px, 0);
  }
  50% {
    opacity: -0.25104;
    -webkit-transform: translate3d(-9 px336px, -14 px250px, 0);
            transform: translate3d(-9 px336px, -14 px250px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(173px, 248px, 0);
            transform: translate3d(173px, 248px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx66 {
  width: 8px;
  height: 8px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run66 38s linear infinite;
          animation: run66 38s linear infinite;
}

@-webkit-keyframes run66 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(101px, 66px, 0);
            transform: translate3d(101px, 66px, 0);
  }
  50% {
    opacity: -0.2497;
    -webkit-transform: translate3d(-12 px101px, -12 px66px, 0);
            transform: translate3d(-12 px101px, -12 px66px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(24px, 431px, 0);
            transform: translate3d(24px, 431px, 0);
  }
}

@keyframes run66 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(101px, 66px, 0);
            transform: translate3d(101px, 66px, 0);
  }
  50% {
    opacity: -0.2497;
    -webkit-transform: translate3d(-12 px101px, -12 px66px, 0);
            transform: translate3d(-12 px101px, -12 px66px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(24px, 431px, 0);
            transform: translate3d(24px, 431px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx67 {
  width: 11px;
  height: 11px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run67 35s linear infinite;
          animation: run67 35s linear infinite;
}

@-webkit-keyframes run67 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(327px, 312px, 0);
            transform: translate3d(327px, 312px, 0);
  }
  50% {
    opacity: -0.42382;
    -webkit-transform: translate3d(-10 px327px, -25 px312px, 0);
            transform: translate3d(-10 px327px, -25 px312px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(170px, 391px, 0);
            transform: translate3d(170px, 391px, 0);
  }
}

@keyframes run67 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(327px, 312px, 0);
            transform: translate3d(327px, 312px, 0);
  }
  50% {
    opacity: -0.42382;
    -webkit-transform: translate3d(-10 px327px, -25 px312px, 0);
            transform: translate3d(-10 px327px, -25 px312px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(170px, 391px, 0);
            transform: translate3d(170px, 391px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx68 {
  width: 12px;
  height: 12px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run68 23s linear infinite;
          animation: run68 23s linear infinite;
}

@-webkit-keyframes run68 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(401px, 260px, 0);
            transform: translate3d(401px, 260px, 0);
  }
  50% {
    opacity: -0.01528;
    -webkit-transform: translate3d(-4 px401px, -28 px260px, 0);
            transform: translate3d(-4 px401px, -28 px260px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(564px, 460px, 0);
            transform: translate3d(564px, 460px, 0);
  }
}

@keyframes run68 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(401px, 260px, 0);
            transform: translate3d(401px, 260px, 0);
  }
  50% {
    opacity: -0.01528;
    -webkit-transform: translate3d(-4 px401px, -28 px260px, 0);
            transform: translate3d(-4 px401px, -28 px260px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(564px, 460px, 0);
            transform: translate3d(564px, 460px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx69 {
  width: 4px;
  height: 4px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run69 25s linear infinite;
          animation: run69 25s linear infinite;
}

@-webkit-keyframes run69 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-22px, 332px, 0);
            transform: translate3d(-22px, 332px, 0);
  }
  50% {
    opacity: -0.30532;
    -webkit-transform: translate3d(-1 px-22px, -13 px332px, 0);
            transform: translate3d(-1 px-22px, -13 px332px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-36px, 288px, 0);
            transform: translate3d(-36px, 288px, 0);
  }
}

@keyframes run69 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-22px, 332px, 0);
            transform: translate3d(-22px, 332px, 0);
  }
  50% {
    opacity: -0.30532;
    -webkit-transform: translate3d(-1 px-22px, -13 px332px, 0);
            transform: translate3d(-1 px-22px, -13 px332px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-36px, 288px, 0);
            transform: translate3d(-36px, 288px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx70 {
  width: 24px;
  height: 24px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run70 30s linear infinite;
          animation: run70 30s linear infinite;
}

@-webkit-keyframes run70 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(150px, 170px, 0);
            transform: translate3d(150px, 170px, 0);
  }
  50% {
    opacity: -0.08162;
    -webkit-transform: translate3d(-16 px150px, -11 px170px, 0);
            transform: translate3d(-16 px150px, -11 px170px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-60px, 240px, 0);
            transform: translate3d(-60px, 240px, 0);
  }
}

@keyframes run70 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(150px, 170px, 0);
            transform: translate3d(150px, 170px, 0);
  }
  50% {
    opacity: -0.08162;
    -webkit-transform: translate3d(-16 px150px, -11 px170px, 0);
            transform: translate3d(-16 px150px, -11 px170px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-60px, 240px, 0);
            transform: translate3d(-60px, 240px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx71 {
  width: 2px;
  height: 2px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run71 31s linear infinite;
          animation: run71 31s linear infinite;
}

@-webkit-keyframes run71 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(358px, 87px, 0);
            transform: translate3d(358px, 87px, 0);
  }
  50% {
    opacity: 0.37856;
    -webkit-transform: translate3d(-29 px358px, -2 px87px, 0);
            transform: translate3d(-29 px358px, -2 px87px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-23px, 115px, 0);
            transform: translate3d(-23px, 115px, 0);
  }
}

@keyframes run71 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(358px, 87px, 0);
            transform: translate3d(358px, 87px, 0);
  }
  50% {
    opacity: 0.37856;
    -webkit-transform: translate3d(-29 px358px, -2 px87px, 0);
            transform: translate3d(-29 px358px, -2 px87px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-23px, 115px, 0);
            transform: translate3d(-23px, 115px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx72 {
  width: 9px;
  height: 9px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run72 23s linear infinite;
          animation: run72 23s linear infinite;
}

@-webkit-keyframes run72 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-36px, 304px, 0);
            transform: translate3d(-36px, 304px, 0);
  }
  50% {
    opacity: 0.46763;
    -webkit-transform: translate3d(-24 px-36px, -14 px304px, 0);
            transform: translate3d(-24 px-36px, -14 px304px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-54px, 595px, 0);
            transform: translate3d(-54px, 595px, 0);
  }
}

@keyframes run72 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-36px, 304px, 0);
            transform: translate3d(-36px, 304px, 0);
  }
  50% {
    opacity: 0.46763;
    -webkit-transform: translate3d(-24 px-36px, -14 px304px, 0);
            transform: translate3d(-24 px-36px, -14 px304px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-54px, 595px, 0);
            transform: translate3d(-54px, 595px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx73 {
  width: 11px;
  height: 11px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run73 37s linear infinite;
          animation: run73 37s linear infinite;
}

@-webkit-keyframes run73 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(95px, 7px, 0);
            transform: translate3d(95px, 7px, 0);
  }
  50% {
    opacity: 0.4015;
    -webkit-transform: translate3d(-11 px95px, -21 px7px, 0);
            transform: translate3d(-11 px95px, -21 px7px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(541px, 314px, 0);
            transform: translate3d(541px, 314px, 0);
  }
}

@keyframes run73 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(95px, 7px, 0);
            transform: translate3d(95px, 7px, 0);
  }
  50% {
    opacity: 0.4015;
    -webkit-transform: translate3d(-11 px95px, -21 px7px, 0);
            transform: translate3d(-11 px95px, -21 px7px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(541px, 314px, 0);
            transform: translate3d(541px, 314px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx74 {
  width: 16px;
  height: 16px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run74 31s linear infinite;
          animation: run74 31s linear infinite;
}

@-webkit-keyframes run74 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(130px, 259px, 0);
            transform: translate3d(130px, 259px, 0);
  }
  50% {
    opacity: -0.30326;
    -webkit-transform: translate3d(-23 px130px, -25 px259px, 0);
            transform: translate3d(-23 px130px, -25 px259px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(598px, 338px, 0);
            transform: translate3d(598px, 338px, 0);
  }
}

@keyframes run74 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(130px, 259px, 0);
            transform: translate3d(130px, 259px, 0);
  }
  50% {
    opacity: -0.30326;
    -webkit-transform: translate3d(-23 px130px, -25 px259px, 0);
            transform: translate3d(-23 px130px, -25 px259px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(598px, 338px, 0);
            transform: translate3d(598px, 338px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx75 {
  width: 11px;
  height: 11px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run75 17s linear infinite;
          animation: run75 17s linear infinite;
}

@-webkit-keyframes run75 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(351px, 100px, 0);
            transform: translate3d(351px, 100px, 0);
  }
  50% {
    opacity: -0.44817;
    -webkit-transform: translate3d(-13 px351px, -16 px100px, 0);
            transform: translate3d(-13 px351px, -16 px100px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(339px, 561px, 0);
            transform: translate3d(339px, 561px, 0);
  }
}

@keyframes run75 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(351px, 100px, 0);
            transform: translate3d(351px, 100px, 0);
  }
  50% {
    opacity: -0.44817;
    -webkit-transform: translate3d(-13 px351px, -16 px100px, 0);
            transform: translate3d(-13 px351px, -16 px100px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(339px, 561px, 0);
            transform: translate3d(339px, 561px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx76 {
  width: 12px;
  height: 12px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run76 38s linear infinite;
          animation: run76 38s linear infinite;
}

@-webkit-keyframes run76 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(181px, 270px, 0);
            transform: translate3d(181px, 270px, 0);
  }
  50% {
    opacity: -0.4777;
    -webkit-transform: translate3d(-2 px181px, -5 px270px, 0);
            transform: translate3d(-2 px181px, -5 px270px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-53px, 103px, 0);
            transform: translate3d(-53px, 103px, 0);
  }
}

@keyframes run76 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(181px, 270px, 0);
            transform: translate3d(181px, 270px, 0);
  }
  50% {
    opacity: -0.4777;
    -webkit-transform: translate3d(-2 px181px, -5 px270px, 0);
            transform: translate3d(-2 px181px, -5 px270px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-53px, 103px, 0);
            transform: translate3d(-53px, 103px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx77 {
  width: 8px;
  height: 8px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run77 18s linear infinite;
          animation: run77 18s linear infinite;
}

@-webkit-keyframes run77 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(345px, 42px, 0);
            transform: translate3d(345px, 42px, 0);
  }
  50% {
    opacity: 0.30379;
    -webkit-transform: translate3d(-2 px345px, -19 px42px, 0);
            transform: translate3d(-2 px345px, -19 px42px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-33px, 514px, 0);
            transform: translate3d(-33px, 514px, 0);
  }
}

@keyframes run77 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(345px, 42px, 0);
            transform: translate3d(345px, 42px, 0);
  }
  50% {
    opacity: 0.30379;
    -webkit-transform: translate3d(-2 px345px, -19 px42px, 0);
            transform: translate3d(-2 px345px, -19 px42px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-33px, 514px, 0);
            transform: translate3d(-33px, 514px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx78 {
  width: 2px;
  height: 2px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run78 27s linear infinite;
          animation: run78 27s linear infinite;
}

@-webkit-keyframes run78 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(124px, 169px, 0);
            transform: translate3d(124px, 169px, 0);
  }
  50% {
    opacity: -0.23693;
    -webkit-transform: translate3d(-16 px124px, -2 px169px, 0);
            transform: translate3d(-16 px124px, -2 px169px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(465px, 684px, 0);
            transform: translate3d(465px, 684px, 0);
  }
}

@keyframes run78 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(124px, 169px, 0);
            transform: translate3d(124px, 169px, 0);
  }
  50% {
    opacity: -0.23693;
    -webkit-transform: translate3d(-16 px124px, -2 px169px, 0);
            transform: translate3d(-16 px124px, -2 px169px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(465px, 684px, 0);
            transform: translate3d(465px, 684px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx79 {
  width: 3px;
  height: 3px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run79 23s linear infinite;
          animation: run79 23s linear infinite;
}

@-webkit-keyframes run79 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(104px, 264px, 0);
            transform: translate3d(104px, 264px, 0);
  }
  50% {
    opacity: 0.05146;
    -webkit-transform: translate3d(-8 px104px, -21 px264px, 0);
            transform: translate3d(-8 px104px, -21 px264px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(102px, 180px, 0);
            transform: translate3d(102px, 180px, 0);
  }
}

@keyframes run79 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(104px, 264px, 0);
            transform: translate3d(104px, 264px, 0);
  }
  50% {
    opacity: 0.05146;
    -webkit-transform: translate3d(-8 px104px, -21 px264px, 0);
            transform: translate3d(-8 px104px, -21 px264px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(102px, 180px, 0);
            transform: translate3d(102px, 180px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx80 {
  width: 12px;
  height: 12px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run80 34s linear infinite;
          animation: run80 34s linear infinite;
}

@-webkit-keyframes run80 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(255px, 207px, 0);
            transform: translate3d(255px, 207px, 0);
  }
  50% {
    opacity: -0.43136;
    -webkit-transform: translate3d(-22 px255px, -29 px207px, 0);
            transform: translate3d(-22 px255px, -29 px207px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(306px, 12px, 0);
            transform: translate3d(306px, 12px, 0);
  }
}

@keyframes run80 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(255px, 207px, 0);
            transform: translate3d(255px, 207px, 0);
  }
  50% {
    opacity: -0.43136;
    -webkit-transform: translate3d(-22 px255px, -29 px207px, 0);
            transform: translate3d(-22 px255px, -29 px207px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(306px, 12px, 0);
            transform: translate3d(306px, 12px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx81 {
  width: 13px;
  height: 13px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run81 18s linear infinite;
          animation: run81 18s linear infinite;
}

@-webkit-keyframes run81 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(157px, -42px, 0);
            transform: translate3d(157px, -42px, 0);
  }
  50% {
    opacity: 0.39321;
    -webkit-transform: translate3d(-25 px157px, -5 px-42px, 0);
            transform: translate3d(-25 px157px, -5 px-42px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(132px, 139px, 0);
            transform: translate3d(132px, 139px, 0);
  }
}

@keyframes run81 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(157px, -42px, 0);
            transform: translate3d(157px, -42px, 0);
  }
  50% {
    opacity: 0.39321;
    -webkit-transform: translate3d(-25 px157px, -5 px-42px, 0);
            transform: translate3d(-25 px157px, -5 px-42px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(132px, 139px, 0);
            transform: translate3d(132px, 139px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx82 {
  width: 5px;
  height: 5px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run82 19s linear infinite;
          animation: run82 19s linear infinite;
}

@-webkit-keyframes run82 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(324px, 233px, 0);
            transform: translate3d(324px, 233px, 0);
  }
  50% {
    opacity: 0.07186;
    -webkit-transform: translate3d(-2 px324px, -28 px233px, 0);
            transform: translate3d(-2 px324px, -28 px233px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(472px, 28px, 0);
            transform: translate3d(472px, 28px, 0);
  }
}

@keyframes run82 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(324px, 233px, 0);
            transform: translate3d(324px, 233px, 0);
  }
  50% {
    opacity: 0.07186;
    -webkit-transform: translate3d(-2 px324px, -28 px233px, 0);
            transform: translate3d(-2 px324px, -28 px233px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(472px, 28px, 0);
            transform: translate3d(472px, 28px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx83 {
  width: 1px;
  height: 1px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run83 30s linear infinite;
          animation: run83 30s linear infinite;
}

@-webkit-keyframes run83 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(360px, 251px, 0);
            transform: translate3d(360px, 251px, 0);
  }
  50% {
    opacity: 0.40341;
    -webkit-transform: translate3d(-8 px360px, -4 px251px, 0);
            transform: translate3d(-8 px360px, -4 px251px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(476px, -59px, 0);
            transform: translate3d(476px, -59px, 0);
  }
}

@keyframes run83 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(360px, 251px, 0);
            transform: translate3d(360px, 251px, 0);
  }
  50% {
    opacity: 0.40341;
    -webkit-transform: translate3d(-8 px360px, -4 px251px, 0);
            transform: translate3d(-8 px360px, -4 px251px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(476px, -59px, 0);
            transform: translate3d(476px, -59px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx84 {
  width: 2px;
  height: 2px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run84 36s linear infinite;
          animation: run84 36s linear infinite;
}

@-webkit-keyframes run84 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(430px, -13px, 0);
            transform: translate3d(430px, -13px, 0);
  }
  50% {
    opacity: -0.09388;
    -webkit-transform: translate3d(-18 px430px, 0 px-13px, 0);
            transform: translate3d(-18 px430px, 0 px-13px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(175px, 232px, 0);
            transform: translate3d(175px, 232px, 0);
  }
}

@keyframes run84 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(430px, -13px, 0);
            transform: translate3d(430px, -13px, 0);
  }
  50% {
    opacity: -0.09388;
    -webkit-transform: translate3d(-18 px430px, 0 px-13px, 0);
            transform: translate3d(-18 px430px, 0 px-13px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(175px, 232px, 0);
            transform: translate3d(175px, 232px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx85 {
  width: 23px;
  height: 23px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run85 35s linear infinite;
          animation: run85 35s linear infinite;
}

@-webkit-keyframes run85 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-12px, 298px, 0);
            transform: translate3d(-12px, 298px, 0);
  }
  50% {
    opacity: 0.06078;
    -webkit-transform: translate3d(-25 px-12px, -4 px298px, 0);
            transform: translate3d(-25 px-12px, -4 px298px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(319px, 624px, 0);
            transform: translate3d(319px, 624px, 0);
  }
}

@keyframes run85 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-12px, 298px, 0);
            transform: translate3d(-12px, 298px, 0);
  }
  50% {
    opacity: 0.06078;
    -webkit-transform: translate3d(-25 px-12px, -4 px298px, 0);
            transform: translate3d(-25 px-12px, -4 px298px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(319px, 624px, 0);
            transform: translate3d(319px, 624px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx86 {
  width: 5px;
  height: 5px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run86 39s linear infinite;
          animation: run86 39s linear infinite;
}

@-webkit-keyframes run86 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(27px, 277px, 0);
            transform: translate3d(27px, 277px, 0);
  }
  50% {
    opacity: 0.05899;
    -webkit-transform: translate3d(-20 px27px, -28 px277px, 0);
            transform: translate3d(-20 px27px, -28 px277px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(294px, 231px, 0);
            transform: translate3d(294px, 231px, 0);
  }
}

@keyframes run86 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(27px, 277px, 0);
            transform: translate3d(27px, 277px, 0);
  }
  50% {
    opacity: 0.05899;
    -webkit-transform: translate3d(-20 px27px, -28 px277px, 0);
            transform: translate3d(-20 px27px, -28 px277px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(294px, 231px, 0);
            transform: translate3d(294px, 231px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx87 {
  width: 18px;
  height: 18px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run87 18s linear infinite;
          animation: run87 18s linear infinite;
}

@-webkit-keyframes run87 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(92px, 80px, 0);
            transform: translate3d(92px, 80px, 0);
  }
  50% {
    opacity: 0.07011;
    -webkit-transform: translate3d(-25 px92px, -11 px80px, 0);
            transform: translate3d(-25 px92px, -11 px80px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(265px, 485px, 0);
            transform: translate3d(265px, 485px, 0);
  }
}

@keyframes run87 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(92px, 80px, 0);
            transform: translate3d(92px, 80px, 0);
  }
  50% {
    opacity: 0.07011;
    -webkit-transform: translate3d(-25 px92px, -11 px80px, 0);
            transform: translate3d(-25 px92px, -11 px80px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(265px, 485px, 0);
            transform: translate3d(265px, 485px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx88 {
  width: 6px;
  height: 6px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run88 24s linear infinite;
          animation: run88 24s linear infinite;
}

@-webkit-keyframes run88 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(212px, 7px, 0);
            transform: translate3d(212px, 7px, 0);
  }
  50% {
    opacity: 0.19231;
    -webkit-transform: translate3d(-9 px212px, -20 px7px, 0);
            transform: translate3d(-9 px212px, -20 px7px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(483px, 676px, 0);
            transform: translate3d(483px, 676px, 0);
  }
}

@keyframes run88 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(212px, 7px, 0);
            transform: translate3d(212px, 7px, 0);
  }
  50% {
    opacity: 0.19231;
    -webkit-transform: translate3d(-9 px212px, -20 px7px, 0);
            transform: translate3d(-9 px212px, -20 px7px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(483px, 676px, 0);
            transform: translate3d(483px, 676px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx89 {
  width: 22px;
  height: 22px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run89 16s linear infinite;
          animation: run89 16s linear infinite;
}

@-webkit-keyframes run89 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(172px, 287px, 0);
            transform: translate3d(172px, 287px, 0);
  }
  50% {
    opacity: 0.28739;
    -webkit-transform: translate3d(-20 px172px, -20 px287px, 0);
            transform: translate3d(-20 px172px, -20 px287px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(50px, 99px, 0);
            transform: translate3d(50px, 99px, 0);
  }
}

@keyframes run89 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(172px, 287px, 0);
            transform: translate3d(172px, 287px, 0);
  }
  50% {
    opacity: 0.28739;
    -webkit-transform: translate3d(-20 px172px, -20 px287px, 0);
            transform: translate3d(-20 px172px, -20 px287px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(50px, 99px, 0);
            transform: translate3d(50px, 99px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx90 {
  width: 20px;
  height: 20px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run90 20s linear infinite;
          animation: run90 20s linear infinite;
}

@-webkit-keyframes run90 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(65px, -12px, 0);
            transform: translate3d(65px, -12px, 0);
  }
  50% {
    opacity: -0.22107;
    -webkit-transform: translate3d(-18 px65px, -28 px-12px, 0);
            transform: translate3d(-18 px65px, -28 px-12px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-24px, 472px, 0);
            transform: translate3d(-24px, 472px, 0);
  }
}

@keyframes run90 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(65px, -12px, 0);
            transform: translate3d(65px, -12px, 0);
  }
  50% {
    opacity: -0.22107;
    -webkit-transform: translate3d(-18 px65px, -28 px-12px, 0);
            transform: translate3d(-18 px65px, -28 px-12px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-24px, 472px, 0);
            transform: translate3d(-24px, 472px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx91 {
  width: 16px;
  height: 16px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run91 17s linear infinite;
          animation: run91 17s linear infinite;
}

@-webkit-keyframes run91 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(23px, 149px, 0);
            transform: translate3d(23px, 149px, 0);
  }
  50% {
    opacity: 0.45386;
    -webkit-transform: translate3d(-13 px23px, -12 px149px, 0);
            transform: translate3d(-13 px23px, -12 px149px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(458px, 418px, 0);
            transform: translate3d(458px, 418px, 0);
  }
}

@keyframes run91 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(23px, 149px, 0);
            transform: translate3d(23px, 149px, 0);
  }
  50% {
    opacity: 0.45386;
    -webkit-transform: translate3d(-13 px23px, -12 px149px, 0);
            transform: translate3d(-13 px23px, -12 px149px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(458px, 418px, 0);
            transform: translate3d(458px, 418px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx92 {
  width: 23px;
  height: 23px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run92 38s linear infinite;
          animation: run92 38s linear infinite;
}

@-webkit-keyframes run92 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-37px, 1px, 0);
            transform: translate3d(-37px, 1px, 0);
  }
  50% {
    opacity: 0.13398;
    -webkit-transform: translate3d(-18 px-37px, -5 px1px, 0);
            transform: translate3d(-18 px-37px, -5 px1px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(206px, -12px, 0);
            transform: translate3d(206px, -12px, 0);
  }
}

@keyframes run92 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-37px, 1px, 0);
            transform: translate3d(-37px, 1px, 0);
  }
  50% {
    opacity: 0.13398;
    -webkit-transform: translate3d(-18 px-37px, -5 px1px, 0);
            transform: translate3d(-18 px-37px, -5 px1px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(206px, -12px, 0);
            transform: translate3d(206px, -12px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx93 {
  width: 23px;
  height: 23px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run93 24s linear infinite;
          animation: run93 24s linear infinite;
}

@-webkit-keyframes run93 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(179px, 100px, 0);
            transform: translate3d(179px, 100px, 0);
  }
  50% {
    opacity: 0.45527;
    -webkit-transform: translate3d(-2 px179px, -21 px100px, 0);
            transform: translate3d(-2 px179px, -21 px100px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(300px, 609px, 0);
            transform: translate3d(300px, 609px, 0);
  }
}

@keyframes run93 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(179px, 100px, 0);
            transform: translate3d(179px, 100px, 0);
  }
  50% {
    opacity: 0.45527;
    -webkit-transform: translate3d(-2 px179px, -21 px100px, 0);
            transform: translate3d(-2 px179px, -21 px100px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(300px, 609px, 0);
            transform: translate3d(300px, 609px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx94 {
  width: 24px;
  height: 24px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run94 18s linear infinite;
          animation: run94 18s linear infinite;
}

@-webkit-keyframes run94 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(175px, 174px, 0);
            transform: translate3d(175px, 174px, 0);
  }
  50% {
    opacity: 0.45401;
    -webkit-transform: translate3d(-29 px175px, -4 px174px, 0);
            transform: translate3d(-29 px175px, -4 px174px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(163px, -7px, 0);
            transform: translate3d(163px, -7px, 0);
  }
}

@keyframes run94 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(175px, 174px, 0);
            transform: translate3d(175px, 174px, 0);
  }
  50% {
    opacity: 0.45401;
    -webkit-transform: translate3d(-29 px175px, -4 px174px, 0);
            transform: translate3d(-29 px175px, -4 px174px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(163px, -7px, 0);
            transform: translate3d(163px, -7px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx95 {
  width: 18px;
  height: 18px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run95 31s linear infinite;
          animation: run95 31s linear infinite;
}

@-webkit-keyframes run95 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(203px, 175px, 0);
            transform: translate3d(203px, 175px, 0);
  }
  50% {
    opacity: 0.09118;
    -webkit-transform: translate3d(-18 px203px, -27 px175px, 0);
            transform: translate3d(-18 px203px, -27 px175px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2px, 609px, 0);
            transform: translate3d(2px, 609px, 0);
  }
}

@keyframes run95 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(203px, 175px, 0);
            transform: translate3d(203px, 175px, 0);
  }
  50% {
    opacity: 0.09118;
    -webkit-transform: translate3d(-18 px203px, -27 px175px, 0);
            transform: translate3d(-18 px203px, -27 px175px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2px, 609px, 0);
            transform: translate3d(2px, 609px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx96 {
  width: 7px;
  height: 7px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run96 35s linear infinite;
          animation: run96 35s linear infinite;
}

@-webkit-keyframes run96 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(352px, 19px, 0);
            transform: translate3d(352px, 19px, 0);
  }
  50% {
    opacity: -0.31623;
    -webkit-transform: translate3d(-28 px352px, -12 px19px, 0);
            transform: translate3d(-28 px352px, -12 px19px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(291px, 304px, 0);
            transform: translate3d(291px, 304px, 0);
  }
}

@keyframes run96 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(352px, 19px, 0);
            transform: translate3d(352px, 19px, 0);
  }
  50% {
    opacity: -0.31623;
    -webkit-transform: translate3d(-28 px352px, -12 px19px, 0);
            transform: translate3d(-28 px352px, -12 px19px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(291px, 304px, 0);
            transform: translate3d(291px, 304px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx97 {
  width: 1px;
  height: 1px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run97 28s linear infinite;
          animation: run97 28s linear infinite;
}

@-webkit-keyframes run97 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(49px, 41px, 0);
            transform: translate3d(49px, 41px, 0);
  }
  50% {
    opacity: 0.42968;
    -webkit-transform: translate3d(-13 px49px, 0 px41px, 0);
            transform: translate3d(-13 px49px, 0 px41px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-6px, 133px, 0);
            transform: translate3d(-6px, 133px, 0);
  }
}

@keyframes run97 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(49px, 41px, 0);
            transform: translate3d(49px, 41px, 0);
  }
  50% {
    opacity: 0.42968;
    -webkit-transform: translate3d(-13 px49px, 0 px41px, 0);
            transform: translate3d(-13 px49px, 0 px41px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-6px, 133px, 0);
            transform: translate3d(-6px, 133px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx98 {
  width: 13px;
  height: 13px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run98 20s linear infinite;
          animation: run98 20s linear infinite;
}

@-webkit-keyframes run98 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(121px, 104px, 0);
            transform: translate3d(121px, 104px, 0);
  }
  50% {
    opacity: -0.39863;
    -webkit-transform: translate3d(-23 px121px, -6 px104px, 0);
            transform: translate3d(-23 px121px, -6 px104px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(399px, 406px, 0);
            transform: translate3d(399px, 406px, 0);
  }
}

@keyframes run98 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(121px, 104px, 0);
            transform: translate3d(121px, 104px, 0);
  }
  50% {
    opacity: -0.39863;
    -webkit-transform: translate3d(-23 px121px, -6 px104px, 0);
            transform: translate3d(-23 px121px, -6 px104px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(399px, 406px, 0);
            transform: translate3d(399px, 406px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx99 {
  width: 5px;
  height: 5px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run99 32s linear infinite;
          animation: run99 32s linear infinite;
}

@-webkit-keyframes run99 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(37px, 132px, 0);
            transform: translate3d(37px, 132px, 0);
  }
  50% {
    opacity: -0.00664;
    -webkit-transform: translate3d(-19 px37px, -1 px132px, 0);
            transform: translate3d(-19 px37px, -1 px132px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-3px, 244px, 0);
            transform: translate3d(-3px, 244px, 0);
  }
}

@keyframes run99 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(37px, 132px, 0);
            transform: translate3d(37px, 132px, 0);
  }
  50% {
    opacity: -0.00664;
    -webkit-transform: translate3d(-19 px37px, -1 px132px, 0);
            transform: translate3d(-19 px37px, -1 px132px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-3px, 244px, 0);
            transform: translate3d(-3px, 244px, 0);
  }
}

/* line 2354, ../sass/screen.scss */
.bx100 {
  width: 22px;
  height: 22px;
  -webkit-transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
          transform: rotate3d(0, 1, 0, 30deg) translate3d(0, 0, -150px);
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  z-index: 5;
  transition: all 5s;
  -webkit-animation: run100 33s linear infinite;
          animation: run100 33s linear infinite;
}

@-webkit-keyframes run100 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(254px, 239px, 0);
            transform: translate3d(254px, 239px, 0);
  }
  50% {
    opacity: 0.30361;
    -webkit-transform: translate3d(-16 px254px, -16 px239px, 0);
            transform: translate3d(-16 px254px, -16 px239px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(82px, -31px, 0);
            transform: translate3d(82px, -31px, 0);
  }
}

@keyframes run100 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(254px, 239px, 0);
            transform: translate3d(254px, 239px, 0);
  }
  50% {
    opacity: 0.30361;
    -webkit-transform: translate3d(-16 px254px, -16 px239px, 0);
            transform: translate3d(-16 px254px, -16 px239px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(82px, -31px, 0);
            transform: translate3d(82px, -31px, 0);
  }
}
@media screen and (max-width: 420px) {
	.ilogo {
  width: 50vw;
  height: 25vw;
  position: absolute;
  z-index: 10;
  top: 5vw;
  left: 5vw;
  background-image: url('/assets/icon/m_logo.png');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 80%;
}
.loginform {
  width: 90vw;
  margin: 0 auto !important;
  padding: 1em;
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
  top: -9em;
  z-index: 10;
}
}
.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.ios ion-segment-button {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #eee;
  border-radius: 0;
  padding: .5em 0;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 1px;
}

.ios .segment-button-checked {
  border-bottom: 1px solid #87A597;
}
.slideroption ion-item{
	margin-bottom:0 !important;
}

.title{
  font-size:1.4rem;
  font-weight: bold;
  margin-bottom: .25em;
  color:#345447;
}

.ptxt .started:before{
  content:"\3001";
}
.ptxt .started:first-child:before{
  content:"";
}

.instructions{
  color:#757575;
  padding-right:.5em;
  font-size:.95em;
}

.instructions ul{
  list-style: square;
}

.instructions ul li{
  padding:.3em 0;
}

.txtcontent{
  width:100%;
}

.modal_page{
  width:90%;
  height:100vh;
  margin:0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal_page .choice_item{
  width:85%;
  margin:0 auto 2em;
}

.item_th{
  color:#555;
  font-size:.9em;
}

.item_th .bold{
  font-weight: bold;
}


.modal_page .choice_item.xs{
  width:50%;
  margin:0 auto 2em;
}
.narrow{
  line-height: 1em;
  margin:0;
  position: relative;
  top:-2px;
}

ion-toast{
  font-size:1.1em;
}

.toast-wrapper{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height:100vh;
  padding: 0 2em;
  z-index:5;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.toast-wrapper .toast-box{
  width:95%;
  background-color: rgba(0,0,0,.85);
  line-height: 1.2;
  padding:.7em 1.2em;
  border-radius: 10px;
  position: relative;
  display: none;
}

.toast-wrapper .toast-box .toast-msg{
  color:#fff;
  font-size:1.1em;
  text-align: center;
}

.toast-wrapper.active .toast-box{
  display: block;
  -webkit-animation-name:toasteffects;
          animation-name:toasteffects;
  -webkit-animation-timing-function:ease-in-out;
          animation-timing-function:ease-in-out;
  -webkit-animation-duration:5s;
          animation-duration:5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  opacity: 0;
}

@-webkit-keyframes toasteffects{
  0%{
      top:20px;
      opacity: 0;
    }
    10%{
        top:0;
        opacity: 1;
    }

    90%{
      top:0;
      opacity: 1;
  }

  100%{
    top:20px;
    opacity: 0;
}
}

@keyframes toasteffects{
  0%{
      top:20px;
      opacity: 0;
    }
    10%{
        top:0;
        opacity: 1;
    }

    90%{
      top:0;
      opacity: 1;
  }

  100%{
    top:20px;
    opacity: 0;
}
}


@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation: fadeIn 1s ease-in-out;
          animation: fadeIn 1s ease-in-out;
}
.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}
.cardctrl ion-card{
	transition : none;
}
.hide{
	display:none;
}

.txtcenter{
  width:100%;
  text-align:center;
  color:#afb5b7;
}



.item .namelist span:after{
  content:",";
  margin-right:5px;
}
.item .namelist span:last-child:after{
  content:"";
}

.cb_green{background-color: #86aa93;
}

.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}
.noteddetail{
  text-align: left;
  padding: 10px 5vw;
  font-size:.9rem;
  margin:0 auto;
  color:#666;
}

.instructions {
  color: #757575;
  margin-top: 0 !important;
  padding: .5em 1em !important;
  font-size: .9em;
  border-bottom:2px solid #eaeaea;
  background-color: #fdf8eb;
}
.instructions ul, .instructions ol, .instructions p{
  margin:0;
}
.instructions ul, .instructions ol{
  padding-left:.5em;
}

.instructions li{
  color: #757575;
  font-size: .9em;
  padding-bottom:.5em;
}
.pretext{
  font-size:.8em;
  color:#aaa;
  padding-left:.5em;
}
.pattern_title{
  font-size:.8em;
  color:#424242;
  padding:.2em 0;
  text-align: center;
  border-bottom: .5px solid #a2a2a2;
  background-color:#cbd0cd;
}

.pattern_title .started:after{
  content:", ";
}
.pattern_title .started:last-child:after{
  content:"";
}
.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.nocontent .noteintro {
  width: 100%;
  padding: 1em 0.5em !important;
  border-bottom:1px solid #ccc;
}
.label-stacked.sc-ion-label-ios-h{
  font-size:17px;
}

.native-textarea.sc-ion-textarea-ios{
  font-size:15px;
}

.required{
  color: #D0021B;
}

.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}
.index-page .mtpage{
	display:flex;
	justify-content:space-between;
	padding:1em;
}
.mtpage .mtitem{
	padding:10px;
}
.mtpage .mtitem:nth-child(1){
	width:58%;
}
.mtpage .mtitem:nth-child(2){
	width:40%;
}

.index-page .slidebox {
  position: relative;
}

.index-page .photobox {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100% * 0.625);
}

.index-page .photobox img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.index-page .photoinfo {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 7px 1em;
  text-align: left;
  background-color: rgba(0, 0, 0, .6);
}

.index-page .photoinfo h3 {
  color: #fff;
  font-size: 1.3rem;
  line-height: 1.2em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
}

.index-page .photoinfo span {
  color: #eee;
  font-size: .9rem;
  line-height: 1.2em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  padding: 0;
}

.index-page .photoinfo span:nth-child(3):before {
  content: "\30FB";

}

.index-page ion-item {
  margin-bottom: 0 !important;

}

.index-page .useritem {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.index-page .useritem h2 {
  font-size: 1.4rem;
  color: #424242;
}

.index-page .useritem h3 {
  font-size: 1.2rem;
  color: #424242;
}

.index-page .useritem .userinfodetail {
  display: flex;
  align-items: center;
  font-size: .82rem;
  color: #666;
}

.index-page .useritem .userinfodetail .amount, .index-page .useritem .userinfodetail .ppamount {
  font-size: 1.9rem;
  color: #333;
  margin-left: .3em;
  position: relative;
  top: -1px;
  display: flex;
  align-items: center;
}

.index-page .useritem .userinfodetail .ppamount:after {
  content: "\4EBA";
  font-size: .6em;
  color: #424242;
  padding-left: .2em;
}

.index-page .sixcol {
  padding: 0 !important;
}

.index-page .sixcol ion-col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: rows;
  height:auto;
  background-color: #fff;
  border-bottom:1px solid #eee;
  cursor:pointer;
  transition: background-color .3s;
}
.index-page .sixcol ion-col:hover {
  background-color: #f8f8f8;

}

.ios .index-page .sixcol ion-col {
  color: #424242;
  height:70px;
}



.index-page .sixcol .bxicon ion-badge {
  position: absolute;
  right: -6px;
  top: -6px;
  min-width: 24px;
  height: 24px;
  padding: 3px 5px 1px !important;
  padding-left: 5px;
  margin: 0 !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 24px;
  border: 2px solid #fff;
  border-radius: 15px;
}

.i_cm {
  background-image: url('/assets/icon/open-books_w.svg');
  background-size: 62%;
  background-color: #87a597;
}

.i_rw {
  background-image: url('/assets/icon/paper-plane.svg');
  background-size: 54%;
  background-color: #c09b6e;
}

.i_gi {
  background-image: url('/assets/icon/leaf.svg');
  background-size: 55%;
  background-color: #b2b583;
}

.i_qa {
  background-image: url('/assets/icon/qa_w.svg');
  background-size: 60%;
  background-color: #ba6ec0;
}

.i_tl {
  background-image: url('/assets/icon/bulb.svg');
  background-size: 62%;
  background-color: #6c77ac;
}

.i_video {
  background-image: url('/assets/icon/film.svg');
  background-size: 62%;
  background-color: #8bbdbb;
}

.i_doc {
  background-image: url('/assets/icon/doc.svg');
  background-size: 62%;
  background-color: #d5ca92;
}


ion-slide{
  width: 100% !important;
}
.mainbtn .maintxt{
  padding-left:.5em;
  font-size:1.15em;
}

.maincol{
  padding-top:3em;
  padding-bottom:3em;
}
.tob{
  margin:1em auto;
}

.mainbtn{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mainbtn h3{
  padding:0 .5em;
  font-size:1.5em;
}
.index-page .sixcol .bxicon {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-bottom: 0;
  margin-right: .7em;
  background-repeat: no-repeat;
  background-position: center;

}

.plus_func_column{
  margin-top:calc(100vh * 0.025);
}

.index-page .sixcol .mainbtn .bxicon {
  width: 48px;
  height: 48px;
}

@media screen and (max-width: 430px) {
  .plus_func_column{
    justify-content: center;
  }

  .mainbtn{
    width: 85%;
    padding-left:.8em;

  } 
	.index-page .mtpage{
		display:block;
		padding:0;
  }
	.mtpage .mtitem{
		width:100%;
		padding:0;
	}
	.mtpage .mtitem:nth-child(1){
	width:100%;
  }
  .mtpage .mtitem:nth-child(2){
	width:100%;
  }
	.index-page .sixcol ion-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-bottom:none;
  padding:1em 0;
  }

  .ios .index-page .sixcol ion-col {
  color: #424242;
  height: auto;
  }

.index-page .maincol ion-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc((100vh - (100vw * 0.75) - 10vh - 58px) / 2);
  background-color: #fff;
  border-bottom:none;

  }

    .ios .index-page .maincol ion-col {
  color: #424242;
  height: calc((100vh - (100vw * 0.75) - 35vh - 44px) / 2);
  }


  .index-page .sixcol .bxicon {
  position: relative;
  width: 12vw;
  height: 12vw;
  border-radius: 50%;
  margin-bottom: .7em;
  margin-right: 0;
  background-repeat: no-repeat;
  background-position: center;

  }

  .index-page .sixcol .mainbtn .bxicon {
    width: 12vw;
    height: 12vw;
    padding:0;
    margin:0;
  }

  .index-page .sixcol .bxicon.small {
    width: 11vw;
    height: 11vw;
    } 

  .mainbtn h3 {
    font-size: 1.4em;
}

  .maincol{
    padding-top:0;
    padding-bottom:0;
  }
}

@media screen and (max-width: 430px) and (max-height:850px){
  .index-page .sixcol .mainbtn .bxicon {
    width: 12vw;
    height: 12vw;
    padding:0;
    margin:0;
}
  .index-page .sixcol ion-col {
    padding:1em 0;
    font-size:.9em;
    }
}

@media screen and (max-width: 430px) and (max-height:800px){
  .tob {
    margin: .5em auto;
  }
  .index-page .sixcol ion-col {
    padding:.5em 0;
    
    }
    .index-page .sixcol .bxicon {
      width: 11vw;
      height: 11vw;
      margin-bottom: .5em;
      font-size:.95em;
      } 
}

@media screen and (max-width: 430px) and (max-height:750px){
  .index-page .sixcol .mainbtn .bxicon {
    width: 12vw;
    height: 12vw;
    padding:0;
    margin:0;
}
  .tob {
    margin: .3em auto;
  }

  .mainbtn h3 {
    font-size: 1.5em;
}
  .index-page .sixcol ion-col {
    padding:.3em 0;
    
    }
    .index-page .sixcol .bxicon {
      width: 12vw;
      height: 12vw;
      margin-bottom: .5em;
      font-size:.95em;
      } 
}

@media screen and (max-width: 430px) and (max-height:700px){
  .index-page .photobox {
    width: 100vw;
    height: calc(100vw * 0.6);
  }
  .tob {
    margin: .2em auto;
  }
  .index-page .sixcol ion-col {
    padding:.3em 0;
    
    }
    .index-page .sixcol .bxicon {
      width: 11vw;
      height: 11vw;
      margin-bottom: .3em;
      font-size:.8em;
      } 
}

@media screen and (max-width: 420px) and (max-height:650px){

  
  .tob {
    margin: .2em auto;
  }
  .index-page .sixcol ion-col {
    padding:.5em 0;
    font-size: .8em;
    }
}

@media screen and (max-width: 375px) {
}
@media screen and (max-width: 375px) and (min-height:750px){
  .index-page .sixcol ion-col {
    padding:.5em 0;
    font-size:.8em;
    }
}

@media screen and (max-width: 375px) and (min-height:700px){
  .index-page .sixcol ion-col {
    padding:.5em 0;
    font-size:.8em;
    }
}

@media screen and (max-width: 375px) and (min-height:600px){
  .index-page .sixcol ion-col {
    padding:.5em 0;
    font-size:.8em;
    }
}

@media screen and (max-width: 360px) {
}

@media screen and (max-width: 360px) and (min-height:750px){
  .mainbtn h3 {
    font-size: 1.7em;
}
}

@media screen and (max-width: 360px) and (min-height:700px){
  .mainbtn h3 {
    font-size: 1.7em;
}
}

@media screen and (max-width: 320px) {
}
.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.stulist ion-label {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: .7em 0;
}

.stulist ion-label .stinfo .title {
  font-size: 1.2rem;
  color: #345447;
}

.stulist ion-label .stinfo .ptxt {
  font-size: .9rem;
  color: #424242;
}

.stulist ion-label .stinfo .ptxt span {
  margin-left: .3em;
}

.stulist ion-label .stinfo .info {
  font-size: 14px;
  color: #999;
  padding:0.5em 0;
}

.stlrsit .curs, .stlrsit .time {
  text-align: right;
}

.stlrsit .curs {
  font-size: 16px;
}

.stlrsit .time {
  font-size: 14px;
  color: #999;

}

.def_item {
  color: #777;
}

.nocontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.nocontent .norole {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 25vh;
}

.nocontent .norole .icon {
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  margin-bottom: .5em;
  opacity: .4;
}

.notify .icon {
  background-image: url('/assets/icon/man-user.svg');
}

.curr .icon {
  background-image: url('/assets/icon/open-books.svg');
}

.nocontent .norole .txtcontent {
  font-size: 18px;
  text-align: center;
  color: #345447;
}

.nocontent .noteintro {
  width: 100%;
  padding:3em .5em 1em ;
  background-color: #fdf8eb;
}

.nocontent .noteintro h4 {
  font-size: 17px;
  font-weight: 600;
  color: #222;
}

.nocontent .noteintro li, .nocontent .noteintro p {
  font-size: 15px;
  color: #777;
  margin-bottom: 1em
}
.nocontent .noteintro li span{
  text-decoration: underline;
}

ion-card {
  --background: #ffffff !important;
}

.otostop {
  color: #888;
  opacity: .6;
}

.currma .crrpj {
  display: flex;
  align-items: center;
}

.currma .crrpj h3 {
	width:5em;
  font-size: 19px;
  color: #333;
  padding-right: 1em;
  letter-spacing: .1em;
}

.currma .crrpj .crrdetail {
  padding-right: 7em;
}

.currma .crrpj .crrdetail .crrdate {
  font-size: 15px;
  color: #424242;
}

.currma .crrpj .crrdetail p {
  font-size: 13px;
  color: #777;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

}

.personal-page ion-label {
  font-size: 1.4rem;
}

.personal-page ion-label .th {
  font-size: 14px;
  color: #777;
  margin-bottom: .7em
}
.slideroption ion-item{
	margin-bottom:0 !important;
}
.pedit{
	font-size:24px  !important;
}
.downloadlink a{
  text-decoration: none;
}
.recivetimetable{
  width:calc(100% - 32px);
  margin:10px auto;
}
.recivetimetable .th{
  font-size: 14px;
  color: #777;
  margin-bottom: .7em; 
}
.recivetimetable .timetable{
  box-shadow: 0 2px 2px rgba(0,0,0,.05);
}

.recivetimetable .timetable .tb{
  display: flex;
  justify-content: space-between;
}

.recivetimetable .timetable .tbheader{
  
}
.recivetimetable .timetable .tb.tbheader .tb_th{
  /* background-color: #eaeee3; */
  background-color: #333;
  color:#fff;
  padding:3px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top:1px solid #dbdbdb;
  font-size:.9rem;
}

.recivetimetable .timetable .tb .tb_th,.recivetimetable .timetable .tb .tb_td{
  width:calc(100% / 8);
  text-align: center;
  padding:5px 0;
  /* border-left:1px solid #dbdbdb; */
  border-bottom:1px solid #dbdbdb;
}
.recivetimetable .timetable .tb .tb_th{
  background-color: #f5f5f5;
  font-size:.9rem;
  color:#424242;
  display: flex;
  align-items: center;
  justify-content: center;
}
.recivetimetable .timetable .tb .tb_td{
  
}
.recivetimetable .timetable .tb .tb_td:last-child{
  /* border-right:1px solid #dbdbdb; */
}
.recivetimetable .timetable .tb.tbheader .tb_th:last-child{
  /* border-right:1px solid #dbdbdb; */
}
.itemname .itgp{
  padding:5px 0 ;
}
.itemname .itgp:before{
  content:"\FF0E   "
}

.instructions{
  color:#757575;
  margin-top:3em;
  padding:0 1em;
  font-size:.95em;
}

.instructions h4{
  font-size:1.2em;
}

.instructions ul{
  list-style: square;
}

.instructions ul li{
  padding:.3em 0;
}

.cus_link{
  margin-top:5em;
  padding:0 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cus_link ion-note{
  font-size:.8em;
  padding-top:.5em;
}

.stinfo .info span:empty{
  display: none;
}
.stinfo .info span:after{
  content:" / ";
  font-weight: 300;
  padding:0 .5em;
}

.stinfo .info span:last-child:after{
  content:""
}

.sub_th{
  font-size:.75em;
  color:#8d8d8d;
  margin:.5em auto 0;
  padding:0 3em;
}



@media screen and (max-width: 430px) {
	.nocontent .norole {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
	.nocontent .norole .icon {
  width: 24vw;
  height: 24vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  margin-bottom: .5em;
  opacity: .4;
}

.video-container {

position: relative;

padding-bottom: 56.25%;

padding-top: 30px;

height: 0;

overflow: hidden;

}

.video-container iframe, .video-container object, .video-container embed {

position: absolute;

top: 0;left: 0;

width: 100%;

height: 100%;}
}
.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

ion-item {
  margin-bottom: .5em;
}

.titlebar {
  display: flex;
  align-items: center;
  padding: 1em;
  margin-top: 1em;
}

.titlebar h3 {
  /*font-size:.9em;*/
  font-size: 21px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
  color: #345447;
}

.titlebar .note {
  display: flex;
  color: #777;
  font-size: 12px;
  margin-left: 1em;
}

.titlebar .note span, .inputform ion-label span {
  color: #D0021B;
}

.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.stmgr .stinfobar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  padding: 2px 0;
}

.stmgr .stinfobar .txt {
  font-size: 14px;
  margin: 0 5px;
  color: #666;
}

.stmgr ion-label .info {
  font-size: 14px;
  margin-top: 3px;
  color: #999;
}

.stmgr ion-label h3 {
  font-size: 1.1em;
  font-weight: 500;
  color: #424242;
}

.cubeline {
  width: 100%;
  height: 2px;
}


.hightlighttitle{
  color:#ea7d1c;
}
.hightlighttitle2{
  color:#3278E4;
}

.cb_red {
  background-color: #ea7d1c;
}
.cb_blue {
  background-color: #3278E4;
}

.cb_green{
  background-color: #86aa93;
}

.item_info span:empty{
  display: none;
}
.item_info span:after{
  content:" / ";
  font-weight: 300;
  padding:0 .5em;
}

.item_info span:last-child:after{
  content:""
}



.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.info{
  color:#777;
  text-align: center;
  padding:.5em;
  font-size:.85em
}
ion-item-sliding.track-ionic ion-label {
  border-left: 2px solid var(--ion-color-primary);
  padding-left: 10px;
}

ion-item-sliding.track-angular ion-label {
  border-left: 2px solid var(--ion-color-angular);
  padding-left: 10px;
}

ion-item-sliding.track-communication ion-label {
  border-left: 2px solid var(--ion-color-communication);
  padding-left: 10px;
}

ion-item-sliding.track-tooling ion-label {
  border-left: 2px solid var(--ion-color-tooling);
  padding-left: 10px;
}

ion-item-sliding.track-services ion-label {
  border-left: 2px solid var(--ion-color-services);
  padding-left: 10px;
}

ion-item-sliding.track-design ion-label {
  border-left: 2px solid var(--ion-color-design);
  padding-left: 10px;
}

ion-item-sliding.track-workshop ion-label {
  border-left: 2px solid var(--ion-color-workshop);
  padding-left: 10px;
}

ion-item-sliding.track-food ion-label {
  border-left: 2px solid var(--ion-color-food);
  padding-left: 10px;
}

ion-item-sliding.track-documentation ion-label {
  border-left: 2px solid var(--ion-color-documentation);
  padding-left: 10px;
}

ion-item-sliding.track-navigation ion-label {
  border-left: 2px solid var(--ion-color-navigation);
  padding-left: 10px;
}

.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.atlist-page .ctpbox {
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
}

.atlist-page ion-card-title {
  font-size: 1.4rem;
  color: #424242;
  line-height: 1.2em;
}

.atlist-page ion-card-subtitle span:nth-child(2):before {
  content: "\30FB";
}

.stulist{
	display:flex;
	flex-wrap:wrap;
}
.stulist ion-card{
	width:30%;
}
@media screen and (max-width: 430px) {
	.stulist{
	display:block;
}
.stulist ion-card{
	width:auto;
}
}

.about-header {
    background-color: #434954;
    padding: 16px;
    width: 100%;
    min-height: 150px;
    text-align: center;
}

.about-header img {
    max-width: 200px;
    min-height: 115px;
    margin-left: -15px;
    padding: 25px 0 20px 0;
}

.about-info p {
    color: #697072;
    text-align: left;
}

.about-info ion-icon {
    width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
    margin-right: 0;
}

.ios .about-info {
    text-align: center;
}

.ios .about-info ion-icon {
    width: auto;
    margin-right: 10px;
}

.arx {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.arx .topcontent {
    width: 45%;
    padding: 2em 1em 1em;

}

.arx .topbox {
    position: -webkit-sticky;
    position: sticky;
    top: 2em;
}

.arx .atcontent {
    width: 55%
}

.atcontent-page .photobox {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
}

.atcontent-page .photobox img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.atcontent-page .topic {
    padding: .5em 1em;
}

.atcontent-page .topic h3 {
    font-size: 1.6rem;
    line-height: 1.4em;
    color: #333;
}

.atcontent-page .topic span {
    color: #999;
}

.atcontent-page .topic span:before {
    content: "\30FB";
}

.atcontent-page .topic span:nth-child(2):before {
    content: "";
}

.atcontent-page .atcontent {
    padding: 0 1em 1em;
}

.atcontent-page .atcontent p {
    color: #424242;
    font-size: 1.4rem;
    line-height: 1.6em;
    text-align: justify;
    margin-bottom: 1.8em;
}

.video {
    position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        overflow: hidden;
        height: 0;
    }

    .video iframe,
    .video object,
    .video embed {

        position: absolute;

        top: 0;
        left: 0;

        width: 100%;

        height: 100%;
    }

@media screen and (max-width: 430px) {

    .arx {
        display: block;
        position: relative;
    }

    .arx .topcontent {
        width: 100%;
        padding: 0;

    }

    .arx .topbox {
        position: inherit;
        top: 0;
    }

    .arx .atcontent {
        width: 100%;

    }

    .video {
    position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        overflow: hidden;
        height: 0;
    }

    .video iframe,
    .video object,
    .video embed {

        position: absolute;

        top: 0;
        left: 0;

        width: 100%;

        height: 100%;
    }

    .atcontent-page .photobox {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: calc(100% * 0.75);
    }
}
.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.atlist-page .ctpbox {
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
}

.atlist-page ion-card-title {
  font-size: 1.4rem;
  color: #424242;
  line-height: 1.2em;
}

.atlist-page ion-card-subtitle span:nth-child(2):before {
  content: "\30FB";
}

.stulist{
	display:flex;
	flex-wrap:wrap;
}
.stulist ion-card{
	width:30%;
}
@media screen and (max-width: 430px) {
	.stulist{
	display:block;
}
.stulist ion-card{
	width:auto;
}
}

.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}
.sendjobpage{
     --padding-top: 47vh;
}
.sendjobpage .nextsoon{
  font-size:1.2rem;
  text-align: center;
  font-weight: 700;
  opacity: .2;
}
.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

ion-item-sliding.track-ionic ion-label {
  border-left: 2px solid var(--ion-color-primary);
  padding-left: 10px;
}

ion-item-sliding.track-angular ion-label {
  border-left: 2px solid var(--ion-color-angular);
  padding-left: 10px;
}

ion-item-sliding.track-communication ion-label {
  border-left: 2px solid var(--ion-color-communication);
  padding-left: 10px;
}

ion-item-sliding.track-tooling ion-label {
  border-left: 2px solid var(--ion-color-tooling);
  padding-left: 10px;
}

ion-item-sliding.track-services ion-label {
  border-left: 2px solid var(--ion-color-services);
  padding-left: 10px;
}

ion-item-sliding.track-design ion-label {
  border-left: 2px solid var(--ion-color-design);
  padding-left: 10px;
}

ion-item-sliding.track-workshop ion-label {
  border-left: 2px solid var(--ion-color-workshop);
  padding-left: 10px;
}

ion-item-sliding.track-food ion-label {
  border-left: 2px solid var(--ion-color-food);
  padding-left: 10px;
}

ion-item-sliding.track-documentation ion-label {
  border-left: 2px solid var(--ion-color-documentation);
  padding-left: 10px;
}

ion-item-sliding.track-navigation ion-label {
  border-left: 2px solid var(--ion-color-navigation);
  padding-left: 10px;
}

.setting-page {
}

.setting-page .serviceinfo {
  margin-top: 5vh;
  padding: 1em;
}

.setting-page .serviceinfo h3 {
  font-size: 22px;
  font-weight: 400;
  color: #345447;
  margin-bottom: 1em;
}

.setting-page .serviceinfo ul {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #424242;
  line-height: 1.4;
}

.setting-page .serviceinfo li {
  margin-bottom: 5px;
}

.setting-page .serviceinfo .cy {
  margin-top: 10px;
  font-size: 13px;
  color: #999;
}

@media screen and (max-width: 360px) {
  .setting-page .serviceinfo {
    margin-top: 2vh;
  }

  .setting-page .serviceinfo h3 {
    font-size: 19px;
  }

  .setting-page .serviceinfo li {
    font-size: 14px;
  }

  .setting-page .serviceinfo .cy {
    margin-top: 5px;
  }

}

@media screen and (max-width: 320px) {
  .setting-page .serviceinfo {
    margin-top: 0;
    padding: 0 1em;
  }

  .setting-page .serviceinfo h3 {
    font-size: 17px;
  }

  .setting-page .serviceinfo li {
    font-size: 13px;
  }

  .setting-page .serviceinfo .cy {
    margin-top: 0;
  }

}

ion-item-sliding.track-ionic ion-label {
  border-left: 2px solid var(--ion-color-primary);
  padding-left: 10px;
}

ion-item-sliding.track-angular ion-label {
  border-left: 2px solid var(--ion-color-angular);
  padding-left: 10px;
}

ion-item-sliding.track-communication ion-label {
  border-left: 2px solid var(--ion-color-communication);
  padding-left: 10px;
}

ion-item-sliding.track-tooling ion-label {
  border-left: 2px solid var(--ion-color-tooling);
  padding-left: 10px;
}

ion-item-sliding.track-services ion-label {
  border-left: 2px solid var(--ion-color-services);
  padding-left: 10px;
}

ion-item-sliding.track-design ion-label {
  border-left: 2px solid var(--ion-color-design);
  padding-left: 10px;
}

ion-item-sliding.track-workshop ion-label {
  border-left: 2px solid var(--ion-color-workshop);
  padding-left: 10px;
}

ion-item-sliding.track-food ion-label {
  border-left: 2px solid var(--ion-color-food);
  padding-left: 10px;
}

ion-item-sliding.track-documentation ion-label {
  border-left: 2px solid var(--ion-color-documentation);
  padding-left: 10px;
}

ion-item-sliding.track-navigation ion-label {
  border-left: 2px solid var(--ion-color-navigation);
  padding-left: 10px;
}
.termsinfo{
  padding:15px;
}

.termsinfo h2{
  font-size:1.5em;
}
.termsinfo h3{
  margin-top: 2em;
  font-size:1.1em;
}
.termsinfo p,.termsinfo li{
  margin-bottom: 1.5em;
}
.termsinfo ol > li > ol{
  margin-top:.5em;
}
.termsinfo ol > li > ol >li{
  list-style-type: upper-alpha; /*大寫英文字母*/
  margin-bottom: 1.1em;
}

.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}
.quandan-page{
  background: #eee;
}

.quandan-page h3{
  padding:0 15px ;
  font-weight: 300;
  font-size:1.5em;
}

.quandan-page ion-label {
  padding: 0.5em 0;
}
.quandan-page ion-list{
  background: transparent;
}
.quandan-page .qusty {
  display: flex;
  align-items: flex-start;
  font-size: 1.1rem;
  color: #D2691E;
}

.quandan-page .qusty .qtxt {
  font-size: 1.4rem;
  font-weight: 300;
  color: #D2691E;
  margin-right: 0.3em;
  position: relative;
  top:-5px;
}

.quandan-page .ans {
  margin-top: 1.4em;
  padding:30px 20px 25px 20px;
  margin-right: 0.5em;
  color: #424242;
  border:1px dotted #87a597;
  border-radius: 18px;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  /*#3880ff;*/
}
.quandan-page .ans:before {
    content:"";
    display: block;
    width:36px;
    height:36px;
    background-image: url('/assets/icon/ans.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  position: absolute;
  z-index:3;
  top:-18px;
  left:5px;
  }
.quandan-page .ans .th{
  display: flex;
  font-size: 1.1rem;
  line-height: 1.2em;
  letter-spacing: 0.02em;
  color:#657C74;
  font-weight: 500;
  position: absolute;
  padding: 5px .5em;
  margin-right:1em;
  top:-15px;
  left:33px;
  background: #fff;
  border-radius: 10px;
}
.quandan-page .ans ol{
  margin-left:22px;
  padding-left:0;
}
.quandan-page .ans p,.quandan-page .ans li{
  letter-spacing: 0.02em;
  line-height: 1.6em;
  font-size:1rem;
  text-align: justify;
  color:#222;
}
.quandan-page .ans li{
  margin-bottom:1em;
}
.quandan-page strong{
  font-weight: 600;
}



/* Ionic Variables and Theming. For more information, please see
// https://beta.ionicframework.com/docs/theming/
// The app direction is used to include
// rtl styles in your app. For more information, please see
// https://beta.ionicframework.com/docs/layout/rtl
// $app-direction: ltr;
// Ionic Colors
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic provides eight layered colors
// that can be changed to theme an app. Additional colors can be
// added as well (see below). For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
// To easily create custom color palettes for your app’s UI,
// check out our color generator:
// https://beta.ionicframework.com/docs/theming/color-generator
*/

:root {
  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;

  --ion-color-primary: #87A597;/*3880ff*/
  --ion-color-primary-rgb: 135, 165, 151;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #7b968a;
  --ion-color-primary-tint: #91ADA0;

  --ion-color-secondary: #B3E5C8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  --ion-color-tertiary: #6B6A70;
  --ion-color-tertiary-rgb: 107, 106, 112;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #58575C;
  --ion-color-tertiary-tint: #858488;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  
    --ion-color-tech: #3880ff;
  --ion-color-tech-rgb: 22, 50, 100;
  --ion-color-tech-contrast: #ffffff;
  --ion-color-tech-contrast-rgb: 255, 255, 255;
  --ion-color-tech-shade: #38e4ff;
  --ion-color-tech-tint: #5238ff;
}

/* Additional Ionic Colors
// --------------------------------------------------
// In order to add colors to be used with Ionic components,
// the color should be added as a class with the convention `.ion-color-{COLOR}`
// where `{COLOR}` is the color to be used on the Ionic component
// and each variant is defined for the color. For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
*/

.ion-color-favorite {
  --ion-color-base: #69bb7b;
  --ion-color-base-rgb: 105, 187, 123;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #5ca56c;
  --ion-color-tint: #78c288;
}

.ion-color-twitter {
  --ion-color-base: #1da1f4;
  --ion-color-base-rgb: 29, 161, 244;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1a8ed7;
  --ion-color-tint: #34aaf5;
}

.ion-color-google {
  --ion-color-base: #dc4a38;
  --ion-color-base-rgb: 220, 74, 56;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #c24131;
  --ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
  --ion-color-base: #23b6ea;
  --ion-color-base-rgb: 35, 182, 234;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1fa0ce;
  --ion-color-tint: #39bdec;
}

.ion-color-facebook {
  --ion-color-base: #3b5998;
  --ion-color-base-rgb: 59, 89, 152;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #344e86;
  --ion-color-tint: #4f6aa2;
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

:root {
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;
  --ion-color-tech : #3880ff;
}

.md {
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: #fff;
  --ion-toolbar-color-activated: #fff;
}
ion-card{
    --background: #ffffff;
  }
body,input{
	font-family: arial;
	font-family: Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '\5FAE\8EDF\96C5\9ED1', Arial, sans-serif;
}

.listpanel .item-native{
  background: yellow !important;
}
