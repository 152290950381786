.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}
.noteddetail{
  text-align: left;
  padding: 10px 5vw;
  font-size:.9rem;
  margin:0 auto;
  color:#666;
}

.instructions {
  color: #757575;
  margin-top: 0 !important;
  padding: .5em 1em !important;
  font-size: .9em;
  border-bottom:2px solid #eaeaea;
  background-color: #fdf8eb;
}
.instructions ul, .instructions ol, .instructions p{
  margin:0;
}
.instructions ul, .instructions ol{
  padding-left:.5em;
}

.instructions li{
  color: #757575;
  font-size: .9em;
  padding-bottom:.5em;
}
.pretext{
  font-size:.8em;
  color:#aaa;
  padding-left:.5em;
}
.pattern_title{
  font-size:.8em;
  color:#424242;
  padding:.2em 0;
  text-align: center;
  border-bottom: .5px solid #a2a2a2;
  background-color:#cbd0cd;
}

.pattern_title .started:after{
  content:", ";
}
.pattern_title .started:last-child:after{
  content:"";
}