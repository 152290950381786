.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}
.cardctrl ion-card{
	transition : none;
}
.hide{
	display:none;
}

.txtcenter{
  width:100%;
  text-align:center;
  color:#afb5b7;
}



.item .namelist span:after{
  content:",";
  margin-right:5px;
}
.item .namelist span:last-child:after{
  content:"";
}

.cb_green{background-color: #86aa93;
}
