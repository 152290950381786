.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}
.index-page .mtpage{
	display:flex;
	justify-content:space-between;
	padding:1em;
}
.mtpage .mtitem{
	padding:10px;
}
.mtpage .mtitem:nth-child(1){
	width:58%;
}
.mtpage .mtitem:nth-child(2){
	width:40%;
}

.index-page .slidebox {
  position: relative;
}

.index-page .photobox {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100% * 0.625);
}

.index-page .photobox img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.index-page .photoinfo {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 7px 1em;
  text-align: left;
  background-color: rgba(0, 0, 0, .6);
}

.index-page .photoinfo h3 {
  color: #fff;
  font-size: 1.3rem;
  line-height: 1.2em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
}

.index-page .photoinfo span {
  color: #eee;
  font-size: .9rem;
  line-height: 1.2em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  padding: 0;
}

.index-page .photoinfo span:nth-child(3):before {
  content: "・";

}

.index-page ion-item {
  margin-bottom: 0 !important;

}

.index-page .useritem {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.index-page .useritem h2 {
  font-size: 1.4rem;
  color: #424242;
}

.index-page .useritem h3 {
  font-size: 1.2rem;
  color: #424242;
}

.index-page .useritem .userinfodetail {
  display: flex;
  align-items: center;
  font-size: .82rem;
  color: #666;
}

.index-page .useritem .userinfodetail .amount, .index-page .useritem .userinfodetail .ppamount {
  font-size: 1.9rem;
  color: #333;
  margin-left: .3em;
  position: relative;
  top: -1px;
  display: flex;
  align-items: center;
}

.index-page .useritem .userinfodetail .ppamount:after {
  content: "人";
  font-size: .6em;
  color: #424242;
  padding-left: .2em;
}

.index-page .sixcol {
  padding: 0 !important;
}

.index-page .sixcol ion-col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: rows;
  height:auto;
  background-color: #fff;
  border-bottom:1px solid #eee;
  cursor:pointer;
  transition: background-color .3s;
}
.index-page .sixcol ion-col:hover {
  background-color: #f8f8f8;

}

.ios .index-page .sixcol ion-col {
  color: #424242;
  height:70px;
}



.index-page .sixcol .bxicon ion-badge {
  position: absolute;
  right: -6px;
  top: -6px;
  min-width: 24px;
  height: 24px;
  padding: 3px 5px 1px !important;
  padding-left: 5px;
  margin: 0 !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 24px;
  border: 2px solid #fff;
  border-radius: 15px;
}

.i_cm {
  background-image: url('/assets/icon/open-books_w.svg');
  background-size: 62%;
  background-color: #87a597;
}

.i_rw {
  background-image: url('/assets/icon/paper-plane.svg');
  background-size: 54%;
  background-color: #c09b6e;
}

.i_gi {
  background-image: url('/assets/icon/leaf.svg');
  background-size: 55%;
  background-color: #b2b583;
}

.i_qa {
  background-image: url('/assets/icon/qa_w.svg');
  background-size: 60%;
  background-color: #ba6ec0;
}

.i_tl {
  background-image: url('/assets/icon/bulb.svg');
  background-size: 62%;
  background-color: #6c77ac;
}

.i_video {
  background-image: url('/assets/icon/film.svg');
  background-size: 62%;
  background-color: #8bbdbb;
}

.i_doc {
  background-image: url('/assets/icon/doc.svg');
  background-size: 62%;
  background-color: #d5ca92;
}


ion-slide{
  width: 100% !important;
}
.mainbtn .maintxt{
  padding-left:.5em;
  font-size:1.15em;
}

.maincol{
  padding-top:3em;
  padding-bottom:3em;
}
.tob{
  margin:1em auto;
}

.mainbtn{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mainbtn h3{
  padding:0 .5em;
  font-size:1.5em;
}
.index-page .sixcol .bxicon {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-bottom: 0;
  margin-right: .7em;
  background-repeat: no-repeat;
  background-position: center;

}

.plus_func_column{
  margin-top:calc(100vh * 0.025);
}

.index-page .sixcol .mainbtn .bxicon {
  width: 48px;
  height: 48px;
}

@media screen and (max-width: 430px) {
  .plus_func_column{
    justify-content: center;
  }

  .mainbtn{
    width: 85%;
    padding-left:.8em;

  } 
	.index-page .mtpage{
		display:block;
		padding:0;
  }
	.mtpage .mtitem{
		width:100%;
		padding:0;
	}
	.mtpage .mtitem:nth-child(1){
	width:100%;
  }
  .mtpage .mtitem:nth-child(2){
	width:100%;
  }
	.index-page .sixcol ion-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-bottom:none;
  padding:1em 0;
  }

  .ios .index-page .sixcol ion-col {
  color: #424242;
  height: auto;
  }

.index-page .maincol ion-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc((100vh - (100vw * 0.75) - 10vh - 58px) / 2);
  background-color: #fff;
  border-bottom:none;

  }

    .ios .index-page .maincol ion-col {
  color: #424242;
  height: calc((100vh - (100vw * 0.75) - 35vh - 44px) / 2);
  }


  .index-page .sixcol .bxicon {
  position: relative;
  width: 12vw;
  height: 12vw;
  border-radius: 50%;
  margin-bottom: .7em;
  margin-right: 0;
  background-repeat: no-repeat;
  background-position: center;

  }

  .index-page .sixcol .mainbtn .bxicon {
    width: 12vw;
    height: 12vw;
    padding:0;
    margin:0;
  }

  .index-page .sixcol .bxicon.small {
    width: 11vw;
    height: 11vw;
    } 

  .mainbtn h3 {
    font-size: 1.4em;
}

  .maincol{
    padding-top:0;
    padding-bottom:0;
  }
}

@media screen and (max-width: 430px) and (max-height:850px){
  .index-page .sixcol .mainbtn .bxicon {
    width: 12vw;
    height: 12vw;
    padding:0;
    margin:0;
}
  .index-page .sixcol ion-col {
    padding:1em 0;
    font-size:.9em;
    }
}

@media screen and (max-width: 430px) and (max-height:800px){
  .tob {
    margin: .5em auto;
  }
  .index-page .sixcol ion-col {
    padding:.5em 0;
    
    }
    .index-page .sixcol .bxicon {
      width: 11vw;
      height: 11vw;
      margin-bottom: .5em;
      font-size:.95em;
      } 
}

@media screen and (max-width: 430px) and (max-height:750px){
  .index-page .sixcol .mainbtn .bxicon {
    width: 12vw;
    height: 12vw;
    padding:0;
    margin:0;
}
  .tob {
    margin: .3em auto;
  }

  .mainbtn h3 {
    font-size: 1.5em;
}
  .index-page .sixcol ion-col {
    padding:.3em 0;
    
    }
    .index-page .sixcol .bxicon {
      width: 12vw;
      height: 12vw;
      margin-bottom: .5em;
      font-size:.95em;
      } 
}

@media screen and (max-width: 430px) and (max-height:700px){
  .index-page .photobox {
    width: 100vw;
    height: calc(100vw * 0.6);
  }
  .tob {
    margin: .2em auto;
  }
  .index-page .sixcol ion-col {
    padding:.3em 0;
    
    }
    .index-page .sixcol .bxicon {
      width: 11vw;
      height: 11vw;
      margin-bottom: .3em;
      font-size:.8em;
      } 
}

@media screen and (max-width: 420px) and (max-height:650px){

  
  .tob {
    margin: .2em auto;
  }
  .index-page .sixcol ion-col {
    padding:.5em 0;
    font-size: .8em;
    }
}

@media screen and (max-width: 375px) {
}
@media screen and (max-width: 375px) and (min-height:750px){
  .index-page .sixcol ion-col {
    padding:.5em 0;
    font-size:.8em;
    }
}

@media screen and (max-width: 375px) and (min-height:700px){
  .index-page .sixcol ion-col {
    padding:.5em 0;
    font-size:.8em;
    }
}

@media screen and (max-width: 375px) and (min-height:600px){
  .index-page .sixcol ion-col {
    padding:.5em 0;
    font-size:.8em;
    }
}

@media screen and (max-width: 360px) {
}

@media screen and (max-width: 360px) and (min-height:750px){
  .mainbtn h3 {
    font-size: 1.7em;
}
}

@media screen and (max-width: 360px) and (min-height:700px){
  .mainbtn h3 {
    font-size: 1.7em;
}
}

@media screen and (max-width: 320px) {
}