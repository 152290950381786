.about-header {
  background-color: #434954;
  padding: 16px;
  width: 100%;
  min-height: 150px;
  text-align: center;
}

.about-header img {
  max-width: 200px;
  min-height: 115px;
  margin-left: -15px;
  padding: 25px 0 20px 0;
}

.about-info p {
  color: #697072;
  text-align: left;
}

.about-info ion-icon {
  width: 20px;
}

.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}

.ios .about-info {
  text-align: center;
}

.ios .about-info ion-icon {
  width: auto;
  margin-right: 10px;
}

.stulist ion-label {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: .7em 0;
}

.stulist ion-label .stinfo .title {
  font-size: 1.2rem;
  color: #345447;
}

.stulist ion-label .stinfo .ptxt {
  font-size: .9rem;
  color: #424242;
}

.stulist ion-label .stinfo .ptxt span {
  margin-left: .3em;
}

.stulist ion-label .stinfo .info {
  font-size: 14px;
  color: #999;
  padding:0.5em 0;
}

.stlrsit .curs, .stlrsit .time {
  text-align: right;
}

.stlrsit .curs {
  font-size: 16px;
}

.stlrsit .time {
  font-size: 14px;
  color: #999;

}

.def_item {
  color: #777;
}

.nocontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.nocontent .norole {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 25vh;
}

.nocontent .norole .icon {
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  margin-bottom: .5em;
  opacity: .4;
}

.notify .icon {
  background-image: url('/assets/icon/man-user.svg');
}

.curr .icon {
  background-image: url('/assets/icon/open-books.svg');
}

.nocontent .norole .txtcontent {
  font-size: 18px;
  text-align: center;
  color: #345447;
}

.nocontent .noteintro {
  width: 100%;
  padding:3em .5em 1em ;
  background-color: #fdf8eb;
}

.nocontent .noteintro h4 {
  font-size: 17px;
  font-weight: 600;
  color: #222;
}

.nocontent .noteintro li, .nocontent .noteintro p {
  font-size: 15px;
  color: #777;
  margin-bottom: 1em
}
.nocontent .noteintro li span{
  text-decoration: underline;
}

ion-card {
  --background: #ffffff !important;
}

.otostop {
  color: #888;
  opacity: .6;
}

.currma .crrpj {
  display: flex;
  align-items: center;
}

.currma .crrpj h3 {
	width:5em;
  font-size: 19px;
  color: #333;
  padding-right: 1em;
  letter-spacing: .1em;
}

.currma .crrpj .crrdetail {
  padding-right: 7em;
}

.currma .crrpj .crrdetail .crrdate {
  font-size: 15px;
  color: #424242;
}

.currma .crrpj .crrdetail p {
  font-size: 13px;
  color: #777;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

}

.personal-page ion-label {
  font-size: 1.4rem;
}

.personal-page ion-label .th {
  font-size: 14px;
  color: #777;
  margin-bottom: .7em
}
.slideroption ion-item{
	margin-bottom:0 !important;
}
.pedit{
	font-size:24px  !important;
}
.downloadlink a{
  text-decoration: none;
}
.recivetimetable{
  width:calc(100% - 32px);
  margin:10px auto;
}
.recivetimetable .th{
  font-size: 14px;
  color: #777;
  margin-bottom: .7em; 
}
.recivetimetable .timetable{
  box-shadow: 0 2px 2px rgba(0,0,0,.05);
}

.recivetimetable .timetable .tb{
  display: flex;
  justify-content: space-between;
}

.recivetimetable .timetable .tbheader{
  
}
.recivetimetable .timetable .tb.tbheader .tb_th{
  /* background-color: #eaeee3; */
  background-color: #333;
  color:#fff;
  padding:3px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top:1px solid #dbdbdb;
  font-size:.9rem;
}

.recivetimetable .timetable .tb .tb_th,.recivetimetable .timetable .tb .tb_td{
  width:calc(100% / 8);
  text-align: center;
  padding:5px 0;
  /* border-left:1px solid #dbdbdb; */
  border-bottom:1px solid #dbdbdb;
}
.recivetimetable .timetable .tb .tb_th{
  background-color: #f5f5f5;
  font-size:.9rem;
  color:#424242;
  display: flex;
  align-items: center;
  justify-content: center;
}
.recivetimetable .timetable .tb .tb_td{
  
}
.recivetimetable .timetable .tb .tb_td:last-child{
  /* border-right:1px solid #dbdbdb; */
}
.recivetimetable .timetable .tb.tbheader .tb_th:last-child{
  /* border-right:1px solid #dbdbdb; */
}
.itemname .itgp{
  padding:5px 0 ;
}
.itemname .itgp:before{
  content:"． "
}

.instructions{
  color:#757575;
  margin-top:3em;
  padding:0 1em;
  font-size:.95em;
}

.instructions h4{
  font-size:1.2em;
}

.instructions ul{
  list-style: square;
}

.instructions ul li{
  padding:.3em 0;
}

.cus_link{
  margin-top:5em;
  padding:0 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cus_link ion-note{
  font-size:.8em;
  padding-top:.5em;
}

.stinfo .info span:empty{
  display: none;
}
.stinfo .info span:after{
  content:" / ";
  font-weight: 300;
  padding:0 .5em;
}

.stinfo .info span:last-child:after{
  content:""
}

.sub_th{
  font-size:.75em;
  color:#8d8d8d;
  margin:.5em auto 0;
  padding:0 3em;
}



@media screen and (max-width: 430px) {
	.nocontent .norole {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
	.nocontent .norole .icon {
  width: 24vw;
  height: 24vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  margin-bottom: .5em;
  opacity: .4;
}

.video-container {

position: relative;

padding-bottom: 56.25%;

padding-top: 30px;

height: 0;

overflow: hidden;

}

.video-container iframe, .video-container object, .video-container embed {

position: absolute;

top: 0;left: 0;

width: 100%;

height: 100%;}
}